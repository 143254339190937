<script>
    import ListView from '../components/ListView.js';
    import EnterpriseNumber from '../components/EnterpriseNumber.vue';
    import ContentBox from '../components/ContentBox.vue';
    import Dropdown from '@/components/DropdownV2';
    import DropdownItem from '@/components/DropdownItem';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import SearchInput from '@/components/SearchInput';
    import CustomTitle from '@/components/Title';

    export default {
        name: 'RslrFiduSearch',
        mixins: [ListView],
        props: {
            currentReseller: {
                type: Object,
            },
        },
        components: {
            EnterpriseNumber,
            ContentBox,
            Dropdown,
            DropdownItem,
            FriendlyButton,
            SearchInput,
            CustomTitle,
        },
        data () {
            const data = this.listViewData({
                apiModel: 'bff/api/search/reseller/fiduciaries',
                defaultSortKey: 'name',
            });

            this.configureSelectFilter(data, 'activeFidus', [
                {
                    value: 'allfidus',
                    label: 'opt-allfidus',
                    filter: null,
                },
                {
                    value: 'archived',
                    label: 'opt-fidus-archived',
                    filter: { state: 'archived' },
                },
                {
                    value: 'active',
                    label: 'opt-fidus-active',
                    filter: { state: 'active' },
                },
            ]);

            return data;
        },
        mounted () {
            this.watchFilters();
            this.search();
        },
        methods: {
            dynamicSearchParams () {
                return { resellerIds: [this.currentReseller.id] };
            },
        },
    };
</script>

<template>
    <div>
        <div class='cb-fidu-title'>
            <CustomTitle class='mt-12 mb-6'>
                {{ $t('h-fidus') }}
            </CustomTitle>
        </div>

        <content-box>
            <div class='flex'>
                <SearchInput v-model='list.textsearch' class='mr-3' />

                <Dropdown
                    v-model='list.filters.activeFidus.selected'
                    default-value='allstates'
                    direction='right'
                >
                    <DropdownItem v-for='option in list.filters.activeFidus.options' :id='option.value' :name='$t(option.label)' :key='option.value'>
                        {{ $t(option.label) }}
                    </DropdownItem>
                </Dropdown>
            </div>
            <pagination
                :list='list'
                :pagination='pagination'
                :ctrls='getControls()'
                size='small'
                class='my-6'
            />

            <div class='cb-bordered' :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table cb-searchresults'>
                    <colgroup>
                        <collumn class='cb-col-md' :sorting='sorting' skey='name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='enterprise_num' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='state' />
                        <col class='cb-col-md'>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ $t('th-fidu-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='name' />
                            </th>
                            <th>
                                <span>{{ $t('th-enterprise-number') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='enterprise_num' />
                            </th>
                            <th>
                                <span>{{ $t('th-status') }}</span>
                            </th>
                            <th>
                                <span>{{ $t('th-documents') }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='(record, i) in list.records' :key='i'>
                            <td>{{ record.name }}</td>
                            <td><enterprise-number :enterprise-number='record.enterprise_num' /></td>
                            <td>{{ record.state }}</td>
                            <td>
                                <template v-if='record.state !== "archived"'>
                                    <FriendlyButton
                                        label='btn-codas'
                                        :action='() => {
                                            $router.push({
                                                name:"rslr-coda-search",
                                                query: {
                                                    fiduciaryId: record.id
                                                },
                                                params: {
                                                    resellerId: currentReseller.id
                                                }
                                            })
                                        }'
                                        square
                                        extra-small
                                        micro
                                        no-margin
                                        class='mr-3'
                                    />
                                    <FriendlyButton
                                        label='btn-sodas'
                                        :action='() => {
                                            $router.push({
                                                name:"rslr-soda-search",
                                                query: {
                                                    fiduciaryId: record.id
                                                },
                                                params: {
                                                    resellerId: currentReseller.id
                                                }
                                            })
                                        }'
                                        square
                                        extra-small
                                        micro
                                        no-margin
                                    />
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <listloader :list='list' />

            <div class='text-center' v-if='!list.loading'>
                <pagination
                    :list='list'
                    :pagination='pagination'
                    :ctrls='getControls()'
                    scroll='scrollTop'
                    no-margin
                />
            </div>
        </content-box>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    modelValue: String,
    label: {
        type: String,
        default: 'placeholder-search-input',
    },
});

const emit = defineEmits(['update:modelValue']);
const inputValue = ref(props.modelValue);

const input = (e) => {
    inputValue.value = e.target.value;
    emit('update:modelValue', e.target.value);
};

watch(() => props.modelValue, (newValue) => {
    inputValue.value = newValue;
});
</script>

<template>
    <div class='search-input'>
        <i class='fa fa-search icon'></i>
        <input type='text' v-model='inputValue' :placeholder='$t(label)' @input='input'>
    </div>
</template>

<style lang="postcss" scoped>
.search-input {
    @apply text-grey-700;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

.search-input .icon {
    @apply left-5;
    position: absolute;
}

.search-input input {
    @apply border border-solid border-grey-250 rounded px-5 pl-12 text-grey-700 h-12;
    width: 100%;
}

.search-input input:focus {
    @apply border border-solid border-blue-300;
    outline: none;
}
</style>

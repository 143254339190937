<script>
    import utils from '../utils.js';

    export default {
        props: {
            value: {
                type: String,
                required: true,
            },
            steps: {
                type: Array,
                required: true,
            },
            history: {
                type: Array,
                required: true,
            },
        },
        computed: {
            mobile () {
                return this.$store.state.gui !== 'widescreen';
            },
            currentStep () {
                for (const step of this.steps) {
                    if (utils.contains(step.keys, this.value)) {
                        return step;
                    }
                }
                return this.steps[0];
            },
            annotatedSteps () {
                const asteps = [];
                let state = 'past';
                const frozen = this.currentStep.freeze || [];

                for (const step of this.steps) {
                    const astep = {
                        keys: step.keys,
                        label: step.label,
                        flags: {},
                    };

                    if (utils.contains(step.keys, this.value)) {
                        state = 'active';
                    }

                    astep.flags[state] = true;
                    astep.flags.visited = utils.containsAny(this.history, step.keys);
                    astep.flags.frozen = utils.containsAny(frozen, step.keys);
                    asteps.push(astep);

                    if (state === 'active') {
                        state = 'future';
                    }
                }

                return asteps;
            },
        },
        watch: {
            value: function (value) {
                utils.pushIfUnique(this.history, value);
            },
        },
        mounted () {
            utils.pushIfUnique(this.history, this.value);
        },
        emits: ['input'],
        methods: {
            setStep (key) {
                for (const step of this.annotatedSteps) {
                    if (utils.contains(step.keys, key)) {
                        const flags = step.flags;
                        if (!flags.active && !flags.frozen  && (flags.past || flags.visited)) {
                            this.$emit('input', key);
                        }
                        return;
                    }
                }
            },
        },
    };
</script>

<template>
    <div class='cb-breadcrumbs' v-if='!mobile'>
        <div
            class='cb-breadcrumb'
            v-for='step of annotatedSteps'
            :class='step.flags'
            @click='setStep(step.keys[0])'
            :key='step.label'
        >
            <span class='cb-breadcrumb-label'>
                {{ $t(step.label) }}
            </span>
        </div>
    </div>
</template>

<style>

.cb-breadcrumbs {
    position: relative;
    top: 40px;
    z-index: 10;
    display: flex;
    margin: 0px auto;
    margin-bottom: -40px;
    max-width: 1160px;
}
.cb-breadcrumb {
    flex: 1;
    position: relative;
    background: linear-gradient(90deg, white,#F9F9FA,#F9F9FA,#F9F9FA);
    color: #bbbbbb;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    user-select: none;
}
.cb-breadcrumb:after {
    content: "";
    user-select: none;
    position: absolute;
    right: -13px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 13px solid #f9f9f9;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    z-index: 10;
}
.cb-breadcrumb:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.cb-breadcrumb:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.cb-breadcrumb:last-child:after {
    display: none;
}

.cb-breadcrumb.active,
.cb-breadcrumb.active:first-child,
.cb-breadcrumb.visited.active,
.cb-breadcrumb.visited.active:first-child {
    background: #C2D4FF;
    color: #5082FF;
    cursor: default;
}
.cb-breadcrumb.active:after,
.cb-breadcrumb.visited.active:after {
  border-left: 13px solid #C2D4FF;
}
.cb-breadcrumb.past,
.cb-breadcrumb.visited {
    background: linear-gradient(90deg, #d5e0ff, #e1e9ff);
    color: #5082FF;
    cursor: pointer;
}
.cb-breadcrumb.past:first-child,
.cb-breadcrumb.visited:first-child {
    background: #e1e9ff;
}
.cb-breadcrumb.past:after,
.cb-breadcrumb.visited:after {
    border-left: 13px solid #e1e9ff;
}
.cb-breadcrumb.frozen {
    cursor: not-allowed !important;
}
.cb-breadcrumb-label {
    position: relative;
    left: 5px;
}
</style>

export const VoilaMandateStates = {
    Invited: 'invited',
    InvitationExpired: 'invitationExpired',
    InValidation: 'inValidation',
    InRegistration: 'inRegistration',
    Problem: 'problem',
    Active: 'active',
    Cancelled: 'cancelled',
};

export const VoilaMandateReasonCodes = {
    AlreadyRegisteredOnPeppol: 'alreadyRegisteredOnPeppol',
    KboVerificationRefused: 'kboVerificationRefused',
    EmailVerificationExpired: 'emailVerificationExpired',
    UserRequested: 'userRequested',
    GeneralError: 'generalError',
};

export const VoilaMandateSubscribedStates = [
    VoilaMandateStates.Invited,
    VoilaMandateStates.InvitationExpired,
    VoilaMandateStates.InValidation,
    VoilaMandateStates.InRegistration,
    VoilaMandateStates.Problem,
    VoilaMandateStates.Active,
];

export const VoilaMandateSearchStates = {
    ...VoilaMandateStates,
    NotInvited: 'notInvited',
};

<script setup>
import ArchiveWarningModal from '../components/ArchiveWarningModal.vue';
import { computed, ref } from 'vue';
import FormRadio from '@/components/FormRadio.vue';
import { gql } from '@apollo/client/core';
import notify from '@/notify';
import validate from '@/validate';
import { t } from '@/i18n';
import { useMutation } from '@vue/apollo-composable';
import Loader from '@/loader';

const checkboxChecked = ref(false);
const confirmReason = ref(null);
const confirm = ref({ confirmed: false });

const emits = defineEmits(['getCodaMandates']);

const props = defineProps({
    mandate: {
        type: Object,
        required: true,
    },
    close: {
        type: Function,
        required: true,
    },
    bankAccountToArchive: {
        type: Object,
        default: null,
    },
    isDirectCustomer: {
        type: Boolean,
        required: true,
    },
});

const isBelfiusOrBnp = computed(() => props.mandate.bankId === 'GEBABEBB' || props.mandate.bankId === 'GKCCBEBB');

function closePopup () {
    confirm.value.confirmed = false;
    confirmReason.value = false;
    checkboxChecked.value = false;
    props.close();
}

async function updateBankMandateState (clientId, bankAccountId, state) {
    try {
        const { mutate: updateBankMandateState } = await useMutation(gql`
            mutation UpdateBankMandateState($input: UpdateBankMandateStateInput!) {
                updateBankMandateState(input: $input) {
                    errors {
                        code,
                        detail,
                        source {
                            pointer
                        }
                    }
                }
            }
        `);
        await updateBankMandateState({
            input: {
                clientId,
                bankAccountId,
                state,
            },
        });
    } catch (e) {
        notify.error(t('err-unknown'));
    }
}

async function archiveBankAccount () {
    Loader.start();
    const codaMandate = props.mandate;
    const bankAccount = props.bankAccountToArchive;
    const stopCodaFileCreation = confirmReason.value;
    try {
        const { mutate: archiveBankAccount } = await useMutation(gql`
            mutation ($input: ArchiveBankAccountInput!) {
                archiveBankAccount(input: $input) {
                    errors {
                        code,
                        detail,
                        source {
                            pointer
                        }
                    }
                }
            }
        `);
        await archiveBankAccount({
            input: {
                clientId: codaMandate.clientId,
                bankAccountId: bankAccount.id,
            },
        });
        if (isBelfiusOrBnp.value && stopCodaFileCreation && ['active', 'pending'].includes(bankAccount.bankMandateState)) {
            updateBankMandateState(codaMandate.clientId, bankAccount.id, 'stop-coda-todo');
        }
        await emits('getCodaMandates');
        notify.success(t('p-archived-bank-account-success'));
    } catch (err) {
        await validate.notifyErrors(err);
    }
    closePopup();
}

async function archiveCodaMandate () {
    Loader.start();
    const codaMandate = props.mandate;
    const bankAccounts = codaMandate.bankAccounts;
    const stopCodaFileCreation = confirmReason.value;
    try {
        const { mutate: archiveCodaMandate } = await useMutation(gql`
            mutation ($input: ArchiveCodaMandateInput!) {
                archiveCodaMandate(input: $input) {
                    errors {
                        code,
                        detail,
                        source {
                            pointer
                        }
                    }
                }
            }
        `);
        await archiveCodaMandate({
            input: {
                clientId: codaMandate.clientId,
                codaMandateId: codaMandate.id,
            },
        });
        if (isBelfiusOrBnp.value && stopCodaFileCreation) {
            bankAccounts.forEach(bankAccount => {
                if (['active', 'pending'].includes(bankAccount.bankMandateState)) {
                    updateBankMandateState(codaMandate.clientId, bankAccount.id, 'stop-coda-todo');
                }
            });
        }
        await emits('getCodaMandates');
        notify.success(t('p-archived-coda-success'));
    } catch (err) {
        await validate.notifyErrors(err);
    }
    closePopup();
}

function checkBoxChecked (value) {
    checkboxChecked.value = true;
    confirmReason.value = value;
}
</script>
<template>
    <ArchiveWarningModal
        show
        :proceed='bankAccountToArchive ? archiveBankAccount : archiveCodaMandate'
        :confirm='confirm'
        :close='closePopup'
        :disable-confirm-button='isBelfiusOrBnp && !checkboxChecked'
    >
        <p class='mb-4'>
            {{ bankAccountToArchive ? $t('p-confirm-archive-bank-account-a') : $t('p-confirm-archive-coda-a') }}
        </p>
        <div v-if='isBelfiusOrBnp'>
            <p>{{ $t('p-confirm-archive-coda-b') }}</p>
            <p>
                {{ $t('p-confirm-archive-coda-c') }}
                <a v-if='!isDirectCustomer' :href='$t("p-confirm-archive-coda-link")' target='_blank'>{{ $t('p-confirm-archive-more-info') }}</a>
            </p>
            <div class='ml-4 mt-2'>
                <FormRadio
                    name='confirmCodaArchivalReason'
                    :value='true'
                    id='accept'
                    @change='value => checkBoxChecked(value)'
                >
                    <span class='font-bold'>{{ $t('p-confirm-archive-coda-stop') }}</span>*
                </FormRadio>
                <FormRadio
                    name='confirmCodaArchivalReason'
                    :value='false'
                    id='decline'
                    @change='value => checkBoxChecked(value)'
                >
                    <span class='font-bold'>{{ $t('p-confirm-archive-coda-no-stop') }},</span> {{ $t('p-confirm-archive-coda-no-stop-info') }}
                </FormRadio>
                <div class='flex flex-row mt-2 italic'>
                    <p class='mr-2'>
                        *
                    </p>
                    <p>{{ $t('p-confirm-archive-coda-d') }}</p>
                </div>
            </div>
        </div>
    </ArchiveWarningModal>
</template>

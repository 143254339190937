<script setup>
import notify from '@/notify';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import { t } from '@/i18n';
import Tooltip from '@/components/Tooltip.vue';

const props = defineProps({
    url: {
        type: String,
        required: false,
    },
    labelLink: {
        type: String,
        default: 'lbl-start-digital-signing',
    },
    labelButton: {
        type: String,
        default: 'lbl-copy-digital-signing-link',
    },
    openLink: {
        type: Boolean,
        default: false,
    },
});

const emits = defineEmits(['showPopup']);

async function copyToClipboard () {
    try {
        await navigator.clipboard.writeText(props.url);
        notify.success(t('p-link-copied-clipboard-success'));
    } catch (err) {
        notify.error(t('p-link-copied-clipboard-error'));
    }
}
</script>

<template>
    <div class='sign-button'>
        <component
            :is='openLink? "a" : "span"'
            :href='url'
            target='_blank'
        >
            <FriendlyButton
                :label='labelLink'
                :action='() => emits("showPopup")'
                extra-small
                square
                micro
                no-margin
            />
        </component>
        <Tooltip display-delayed min-width-auto>
            <template #trigger>
                <FriendlyButton
                    id='copy-signing-link'
                    :action='copyToClipboard'
                    square
                    micro
                    no-margin
                    symbol='clipboard'
                    class='ml-2'
                    no-text
                    no-background
                    secondary
                    fat-icon
                />
            </template>
            <template #content>
                {{ $t(labelButton) }}
            </template>
        </Tooltip>
    </div>
</template>

<style lang='postcss' scoped>
.sign-button {
    @apply rounded-md ;
    display: flex;
    height: 30px;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
}
</style>

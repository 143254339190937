<script>
    export default {
        name: 'TwikeyNolink',
        props: {
            url: String,
        },
        computed: {
            unsupported () {
                return !this.url || this.url.indexOf('supported') >= 0;
            },
        },
    };
</script>

<template>
    <span>
        <span v-if='unsupported' class='text-grey-300 italic'>
            {{ $t('lbl-unsupported') }}
        </span>
        <a
            v-else
            href='https://www.twikey.com'
            target='_blank'
        >
            <span>{{ $t('lbl-provided-by') }}</span>
            <img class='cb-btn-twikey' src='/static/images/twikey-logo-small.png'>
        </a>
    </span>
</template>

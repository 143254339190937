<script>
    export default {
        name: 'ListLoader',
        props: {
            list: {
                type: Object,
                required: true,
            },
        },
    };
</script>
<template>
    <div class='cb-list-loader' v-if='list.loading'>
        <i class='fa fa-circle-o-notch fa-spin'></i>
    </div>
</template>

<script>
export default {
    name: 'ListComponent',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        tooltipFix: {
            type: Boolean,
            default: false,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<template>
    <div class='list-container' :class='{scrollable, "tooltip-fix": tooltipFix}'>
        <span class='hidden'><slot></slot></span>
        <table class='list table-auto w-full'>
            <thead>
                <tr>
                    <slot name='columns'></slot>
                </tr>
            </thead>
            <tbody class='py-6 border-0' :class='{"border-solid border-t-2 border-grey-200": !noBorder}'>
                <slot name='rows'></slot>
            </tbody>
        </table>
        <div v-if='loading' class='flex justify-center items-center text-4xl my-12 h-full'>
            <i class='fa fa-circle-o-notch fa-spin text-grey-250'></i>
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.list tbody:before {
    @apply block h-3;
    content: '';
}
.list-container.tooltip-fix {
    padding-bottom: 135px;
}

.list-container.scrollable {
    overflow-y: auto;
    padding-bottom: 0;
    overflow-x: auto;
}
</style>

<script setup>
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import Tooltip from '@/components/Tooltip.vue';
import Loader from '@/loader';
import { useMutation } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import notify from '@/notify';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    delegatesMandateDelivery: {
        type: Boolean,
        required: true,
    },
    mandate: {
        type: Object,
    },
});

async function doSend () {
    Loader.start();
    const { mutate: sendCodaMandateSigningMail } = await useMutation(gql`
        mutation SendSigningMail($input: SendSigningMailInput!) {
            sendCodaMandateSigningMail(input: $input) {
                errors {
                    code
                    detail
                    source {
                        pointer
                    }
                }
            }
        }
    `);

    try {
        const res = (await sendCodaMandateSigningMail({ input: { codaMandateId: props.mandate.id, clientId: props.mandate.clientId } })).data.sendCodaMandateSigningMail;

        if (res.errors) {
            notify.error(t('err-unknown-retry-later'));
            return;
        }
        notify.success(t('lbl-success-send-signing-mail'));
    } catch (error) {
        notify.error(t('err-unknown-retry-later'));
    }
    Loader.stop();
}

</script>

<template>
    <div v-if='delegatesMandateDelivery'>
        <Tooltip display-delayed min-width-max no-wrap data-test='send-signing-mail-button'>
            <template #trigger>
                <FriendlyButton
                    id='send-signing-mail'
                    :action='doSend'
                    square
                    micro
                    no-margin
                    symbol='send'
                    class='ml-2'
                    no-text
                    no-background
                    secondary
                    fat-icon
                />
            </template>
            <template #content>
                {{ t('lbl-send-signing-mail') }}
            </template>
        </Tooltip>
    </div>
</template>

<style lang='postcss' scoped>

</style>

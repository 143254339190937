<template>
    <div
        v-show='isVisible'
        class='text-orange-300 px-4 py-3 bg-grey-100 inline-block rounded-md flex items-center'
        :id='id'
    >
        <ul class='w-full list-none pl-0'>
            <slot></slot>
        </ul>
    </div>
</template>

<script setup>
import { provide, ref, watchEffect } from 'vue';

defineProps({
    id: {
        type: String,
        required: false,
    },
});

const items = ref([]);
provide('items', items);

const isVisible = ref(true);

watchEffect(() => {
    isVisible.value = items.value.length > 0;
});
</script>

<template>
    <div>
        <NavBar no-nav no-login />
        <div class='maintenance-page'>
            <div class='w-full pb-40 px-6 sm:w-2/3 sm:mx-auto xl:w-7/12 text-cente grid lg:grid-cols-2 gap-12'>
                <img src='@/assets/maintenance.jpg' alt='maintenance image' class='image'>
                <div class='flex items-center text-blue-700'>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p class='text-4xl text-bold' v-html='$t("p-maintenance")'></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';

export default {
    name: 'MaintenancePage',
    components: {
        NavBar,
    },
};
</script>

<style lang="postcss" scoped>
.maintenance-page {
    @apply flex bg-cover bg-center flex-col w-screen justify-center items-center pt-6;
    background-image: url('~@/assets/login-background.svg');
    min-height: calc(100vh - 50px);
}

.image {
    @apply rounded-md w-full;
}
</style>

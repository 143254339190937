<script>
    export default {
        name: 'SodaTermsFr',
        props: {
            isDirectCustomer: Boolean,
        },
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Description du service
        </h3>

        <p>
            Le service SODA de Codabox consiste à verser les écritures de paie des différents secrétariats sociaux dans un format XML numérique standardisé (développé et enregistré par Codabox), ce qui vous permet de les importer facilement et directement dans votre logiciel comptable.
            Vous recevez également des informations analytiques et des corrections éventuelles.
            Grâce à cette automatisation, la collecte et la codification individuelle des pièces comptables envoyées séparément par le secrétariat social appartiennent au passé.
        </p>

        <p>
            <a href='https://www.codabox.com/fr/services/soda/secretariats-sociaux-partenaires/' target='_blank'>Liste des Secrétariats Sociaux connectés </a>
        </p>

        <p>
            Les fichiers SODA sont importés régulièrement dans votre logiciel comptable en tant qu’opérations diverses.
            Notez que les fichiers SODA sont destinés aux ventilations comptables et ne comprennent donc pas de données de facturation entre le secrétariat social et l’entreprise.
        </p>

        <p>
            <a href='https://www.codabox.com/fr/services/soda/softwares-prets-pour-lintegration/' target='_blank'>Liste des Logiciels de comptabilité compatibles</a>
        </p>

        <p>
            Via MyCodabox, vous pouvez commander SODA et suivre l'état d'avancement de vos demandes.
            Pour activer SODA, il doit y avoir au moins un mandat CODA actif pour l'entreprise (dossier).
        </p>

        <h3>Tarifs</h3>

        <h4 class='cb-service-title'>
            Frais administratifs
        </h4>

        <p v-if='isDirectCustomer'>
            24 € par abonnement SODA (après réception du premier fichier)
        </p>
        <p v-else>
            Lorsqu’un mandat SODA est activé, dès la première réception d’un fichier pour ce mandat, vous serez facturé de manière unique d’un montant de 6,00 € par mandat SODA.
        </p>

        <h4 class='cb-service-title'>
            Frais récurrents
        </h4>

        <template v-if='isDirectCustomer'>
            <table class='table'>
                <tbody>
                    <tr>
                        <td>> 2 abonnements SODA actifs</td>
                        <td>7,73 €/mois/abonnement</td>
                    </tr>
                    <tr>
                        <td>1 - 2 abonnements SODA actifs</td>
                        <td>9,62 €/mois/abonnement</td>
                    </tr>
                </tbody>
            </table>
            <p>
                Si vous gérez plusieurs entreprises sous un seul contrat Codabox, le nombre total d'abonnements SODA détermine le tarif des frais récurrents.
            </p>
            <p class='note'>
                <span class='fa fa-info-circle'></span>
                SODA reste actif jusqu'à ce que vous archiviez le service via MyCodabox ou que le mandat soit arrêté.
            </p>
        </template>

        <p v-if='!isDirectCustomer'>
            Dès l’activation du service SODA pour votre bureau comptable, vous serez facturé mensuellement d’un montant forfaitaire de 68,79 €, indépendamment du nombre de mandats SODA commandés.
        </p>

        <h3>Conditions Générales</h3>
        <p v-if='isDirectCustomer'>
            <a href='/static/pdfs/20250101 Contrat CodaBox Business CODA-SODA-CARO_FR.pdf' target='_blank'>Contrat Codabox Business</a>
        </p>
        <p v-else>
            <a href='/static/pdfs/MyCodabox.com 202501 CB-Fidu FR-Annex SODA (B2-C2-D).pdf' target='_blank'>Conditions générales du service</a>
        </p>
    </div>
</template>

<style lang='scss' scoped>
    h3.cb-service-main-title {
        margin-top: 10px;
    }
    h4.cb-service-title {
        text-decoration: underline;
    }
    p.note {
        font-style: italic;
    }
    p.note .fa-info-circle {
        font-size: 16px;
    }
    p, table {
        margin: 10px 0;
    }
    table {
        table-layout: fixed;
    }
    tbody {
        color: $primary-color-dark;
    }
</style>

<template>
    <div class='text-center flex flex-col items-center justify-center mb-auto'>
        <NavBar :user='$store.state.user' />
        <ConsentConfirm
            :consent-id='$route.query.uuid'
            :on-done='redirect'
            :on-error='redirect'
            :on-success='redirectToAccountingSoftware'
        />
        <cb-footer />
    </div>
</template>

<script>
import CbFooter from '@/components/FancyFooter.vue';
import NavBar from '@/components/NavBar.vue';
import ConsentConfirm from '@/fiduciary/ConnectApiConsentConfirm.vue';
import router from '@/router.js';

export default {
    components: {
        ConsentConfirm,
        CbFooter,
        NavBar,
    },
    methods: {
        redirect (consent) {
            router.push({ name: 'organization-administration-connect', params: { organizationId: consent.accountantId } });
        },
        redirectToAccountingSoftware (consent) {
            setTimeout(() => { window.location.href = consent.redirectUri; }, 5000);
        },
    },
};
</script>

import Api from '@/api';
import store from '@/store';

function setAuthToken (token) {
    // Set token in old wrapper for old API calls
    Api.token = token;

    // Set token in store.state, used in the new auth wrapper for API calls
    store.dispatch('setToken', token);

    // Set token in localStorage, used for Apollo calls
    if (token) {
        localStorage.authToken = token;
    } else {
        delete localStorage.authToken;
    }
}

function setIdToken (token) {
    // Set token in old wrapper for old API calls
    Api.idToken = token;

    // Set token in store.state, used in the new auth wrapper for API calls
    store.dispatch('setIdToken', token);

    // Set token in localStorage, used for Apollo calls
    if (token) {
        localStorage.idToken = token;
    } else {
        delete localStorage.idToken;
    }
}

export default {
    setAuthToken,
    setIdToken,
};

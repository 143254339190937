import { reactive } from 'vue';

const state = reactive({
    loading: false,
    progress: 0,
    cssprgrss: '0%',
    loadingId: 0,
    progressInterval: null,
});

export default {
    start () {
        state.loadingId += 1;
        state.loading = true;
        state.progressInterval = setInterval(function () {
            state.progress += 0.01;
            if (state.progress > 1.0) {
                state.progress = 0;
            }
            state.cssprgrss = -state.progress * 100 + '%';
        }, 1000 / 60);

        return state.loadingId;
    },

    stop (id) {
        if (!id || id === state.loadingId) {
            clearInterval(state.progressInterval);
            state.loading = false;
            state.loadingId += 1;
        }
    },

    getState () {
        return state;
    },
};

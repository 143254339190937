<script>
import loader from '../loader';
import { toRefs } from 'vue';

export default {
    name: 'LoaderComponent',
    setup () {
        const loaderState = loader.getState();

        return {
            ...toRefs(loaderState),
        };
    },
};
</script>

<template>
    <div class='cb-loader' :class='{ visible: loading }'></div>
</template>

<style scoped>
/* ------------------ *\
  |*       LOADER       *|
  \*--------------------*/
.cb-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    z-index: 1000;
    overflow: hidden;
    transition: opacity 250ms ease;
    opacity: 0;
}

.cb-loader:before {
    @apply bg-green-300;
    content: "";
    position: absolute;
    left: -50%;
    height: 3px;
    width: 40%;
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }

    50% {
        left: 20%;
        width: 80%;
    }

    100% {
        left: 100%;
        width: 100%;
    }
}

.cb-loader.visible {
    opacity: 1;
}
</style>

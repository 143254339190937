<script setup>
import { ref } from 'vue';
import Popup from '@/clientcomponents/Popup';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';

defineProps({
    vid: {
        type: String,
        required: true,
    },
    confirmationTitle: {
        type: String,
        required: true,
    },
    confirmationText: {
        type: String,
        required: true,
    },
    show: {
        type: Boolean,
        required: true,
    },
});

const isConfirmationCheckboxChecked = ref(false);

const emits = defineEmits(['confirm', 'close']);

function close () {
    isConfirmationCheckboxChecked.value = false;
    emits('close');
}

function confirmed () {
    emits('confirm');
}

</script>

<template>
    <Popup
        :show='show'
        :close='close'
        small
        :vid='vid'
    >
        <template #header>
            {{ confirmationTitle }}
        </template>

        <slot></slot>

        <div class='form-checkbox'>
            <input type='checkbox' :id='`${vid}-checkbox`' v-model='isConfirmationCheckboxChecked'>
            <label :for='`${vid}-checkbox`' v-if='confirmationText'>
                {{ confirmationText }}
            </label>
        </div>

        <template #buttons>
            <div class='flex'>
                <FriendlyButton
                    label='btn-cancel'
                    class='mr-3 ml-auto'
                    :action='close'
                    symbol='times'
                    small
                    no-margin
                    secondary
                    :id='`${vid}-cancel`'
                />
                <FriendlyButton
                    label='btn-voila-no-email-confirm'
                    small
                    no-margin
                    symbol='check'
                    :disabled='!isConfirmationCheckboxChecked'
                    :id='`${vid}-confirm`'
                    :action='confirmed'
                />
            </div>
        </template>
    </Popup>
</template>

<style scoped>

.form-checkbox {
    @apply flex items-center;
}

label {
    @apply text-grey-700 font-bold m-0 ml-3;
}

.form-checkbox input[type="checkbox"] {
    @apply cursor-pointer mt-0;
}

.form-checkbox input[type="checkbox"]:disabled {
    @apply bg-blue-200 cursor-not-allowed;
}

</style>

import Api from '../api';
import utils from '../utils.js';
import Searchbox from '../components/Searchbox.vue';
import Selector  from '../components/Selector.vue';
import Pagination from '../components/Pagination.vue';
import Collumn    from '../components/Collumn.vue';
import Sorttoggle from '../components/Sorttoggle.vue';
import ListLoader from '../components/ListLoader.vue';

export default {
    components: {
        searchbox:  Searchbox,
        selector:   Selector,
        pagination: Pagination,
        collumn:    Collumn,
        sorttoggle: Sorttoggle,
        listloader: ListLoader,
    },
    watch: {
        'list.textsearch': function () {
            this.onUserInput();
        },
        '$i18n.locale': function () {
            this.translateFilters();
        },
    },

    methods: {
        listViewData (extra) {
            extra = extra || {};

            const data = {};

            data.pagination = {
                page: 1,
                pageCount: 1,
                limit: this.getSavedPageLimit(),
                recordCount: 0,
                cluster: 5,
                prev: false,
                next: false,
                pages: [
                    { number: 1, active: true },
                ],
            };

            data.sorting = this.loadSavedSorting(extra.defaultSortKey);

            data.list = {
                loading: true,
                reloading: false,
                filters: {},
                textsearch: '',
                records: [],
                fetchId: 0,
                selectToggle: false,
                selectedRecords: [],
            };

            return utils.extend(data, extra);
        },

        getSavedPageLimit () {
            let limit = Number(localStorage['cb-page-limit'] || '50');
            limit = limit || 50;
            return limit;
        },

        setPageLimit (count) {
            this.pagination.limit = count || 50;
            localStorage['cb-page-limit'] = '' + this.pagination.limit;
            this.fetchRecords();
        },

        onUserInput () {
            clearTimeout(this._onUserInputTimeout);
            this._onUserInputTimeout = setTimeout(() => {
                this.pagination.page = 1;
                this.fetchRecords();
            }, 300);
        },

        /* ----------------- *\
         *   SEARCH FILTERS  *
        \* ----------------- */

        configureSelectFilter (data, name, options, selected) {
            const filter = {
                type: 'select',
                options,
                selected: selected || options[0].value,
            };

            data.list.filters[name] = filter;

            const path = 'list.filters.' + name + '.selected';

            this.$watch(path, () => {
                this.fetchRecords();
            });
        },

        setSelectFilterValue (name, value) {
            const filter = this.list.filters[name];
            let found  = false;

            for (const opt of filter.options) {
                if (opt.value === value) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                console.warn('Selecting unknown value:', value);
                filter.selected = null;
            } else {
                filter.selected = value || null;
            }
        },

        configureFieldFilter (data, name, field, value) {
            const filter = {
                type: 'field',
                field,
                value: value || null,
            };

            data.list.filters[name] = filter;
        },

        setFieldFilterValue (name, value) {
            this.list.filters[name].value = value || null;
        },

        getSelectedFilters () {
            function getSelected (filter) {
                if (!filter) {
                    return null;
                } else if (filter.type === 'select') {
                    if (!filter.selected) {
                        return null;
                    }
                    for (let i = 0; i < filter.options.length; i++) {
                        if (filter.options[i].value === filter.selected) {
                            return filter.options[i].filter;
                        }
                    }
                } else if (filter.type === 'field') {
                    if (filter.value) {
                        const f = {};
                        f[filter.field] = filter.value;
                        return f;
                    } else {
                        return null;
                    }
                }
            }

            let filters = {};

            for (const name in this.list.filters) {
                filters = utils.extend(filters, getSelected(this.list.filters[name]) || {});
            }

            return filters;
        },

        translateFilters () {
            for (const name in this.list.filters) {
                const filter = this.list.filters[name];
                for (const opts of filter.options || []) {
                    if (opts.tr) {
                        opts.label = this.$t(opts.tr);
                    }
                }
            }
        },

        watchFilters () {
            for (const filter in this.list.filters) {
                this.$watch('list.filters.' + filter + '.selected', () => {
                    this.onUserInput();
                });
            }
        },

        /* ----------------- *\
         *     SEARCHING     *
        \* ----------------- */

        extendRecords (records) {
            // extend this if you want to modify the records before they are displayed ...
            return Promise.resolve(records);
        },

        // wrapper around the search to feed mockup results if necessary.
        _fetchRecords (url) {
            return Api.rpc('GET', url);
        },

        getNewFetchId () {
            // Used to identify each search action so that late result from
            // previous searches don't overwrite recent ones.
            this.list.fetchId += 1;
            return this.list.fetchId;
        },

        // To be overridden by child components
        dynamicSearchParams () {
            return {};
        },

        fetchRecords (scroll) {
            let params = {};

            if (this.defaultFilters) {
                // extend dict to avoid updating value from defaultFilters when updating params
                params = utils.extend(params, this.defaultFilters);
            }

            params = utils.extend(params, this.dynamicSearchParams());

            if (!this.disablePagination) {
                params.limit  = this.pagination.limit;
                params.offset = (this.pagination.page - 1) * this.pagination.limit;
            }

            const fetchId = this.getNewFetchId();

            if (this.sorting.key) {
                if (this.sorting.dir > 0) {
                    params.ordering = this.sorting.key;
                } else {
                    params.ordering = '-' + this.sorting.key;
                }
            }

            const filters = this.getSelectedFilters();
            for (const f in filters) {
                params[f] = '' + filters[f] + '';
            }

            if (this.list.textsearch) {
                params.search = '' + this.list.textsearch + '';
            }

            const requrl = this.apiModel + '/' + utils.serializeQuery(params);

            this.list.reloading = true;

            this._fetchRecords(requrl).then((res) => {
                if (this.isRowData) {
                    res = {
                        res: res.length,
                        results: res,
                    };
                }

                if (fetchId !== this.list.fetchId) {
                    return;  // The results do not correspond to the latest search.
                }

                if (scroll === 'scrollTop') {
                    utils.scrollTop();
                }

                this.list.loading = false;

                this.pagination.recordCount  = res.count;

                if (this.disablePagination) {
                    this.pagination.pageCount = 1;
                    this.pagination.limit = res.count;
                } else {
                    this.pagination.pageCount = Math.ceil(res.count / this.pagination.limit);
                }

                if (this.pagination.page > this.pagination.pageCount) {  // should not happen ...
                    this.pagination.page = this.pagination.pageCount;
                }

                this.extendRecords(res.results).then((records) => {
                    this.list.reloading = false;

                    this.list.records = records.slice(0, this.pagination.limit);
                    this.computePaging();
                });
            });
        },

        search (scroll) {
            this.pagination.page = 1;
            this.clearSelection();
            return this.fetchRecords(scroll);
        },

        /* ----------------- *\
         *      SORTING      *
        \* ----------------- */

        loadSavedSorting (defaultKey) {
            const sorting = JSON.parse(localStorage['cb-sorting-' + this.apiModel] || '{}');
            sorting.key = sorting.key || defaultKey || null;
            sorting.dir = sorting.dir || 1;
            return sorting;
        },

        saveSorting () {
            localStorage['cb-sorting-' + this.apiModel] = JSON.stringify(this.sorting);
        },

        toggleSort (key) {
            if (key === this.sorting.key) {
                this.sorting.dir = -this.sorting.dir;
            } else {
                this.sorting.key = key;
                this.sorting.dir = 1;
            }
            this.saveSorting();
            return this.fetchRecords();
        },

        /* ----------------- *\
         *    PAGINATION     *
        \* ----------------- */

        nextPage (scroll) {
            const newpage = this.pagination.page + 1;
            if (newpage <= this.pagination.pageCount) {
                this.pagination.page = newpage;
                return this.fetchRecords(scroll);
            }
        },

        prevPage (srcoll) {
            const newpage = this.pagination.page - 1;
            if (newpage >= 1) {
                this.pagination.page = newpage;
                return this.fetchRecords(scroll);
            }
        },

        setPage (page, scroll) {
            if (page >= 1 && page <= this.pagination.pageCount && page !== this.pagination.page) {
                this.pagination.page = page;
                return this.fetchRecords(scroll);
            }
        },

        computePaging () {
            this.pagination.prev  = this.pagination.page > 1;
            this.pagination.next  = this.pagination.page < this.pagination.pageCount;
            this.pagination.pages = [];

            let i = 0;

            if (this.pagination.pageCount <= this.pagination.cluster) {
                for (i = 1; i <= this.pagination.pageCount; i++) {
                    this.pagination.pages.push({ number: i, active: i === this.pagination.page });
                }
            } else {
                let start = this.pagination.page - Math.floor(this.pagination.cluster / 2);
                start = Math.max(start, 1);
                start = Math.min(start, this.pagination.pageCount - this.pagination.cluster + 1);

                const end = start + this.pagination.cluster - 1;

                for (i = start; i <= end; i++) {
                    this.pagination.pages.push({ number: i, active: i === this.pagination.page });
                }
            }
        },

        getControls () {
            return {
                nextPage: this.nextPage,
                prevPage: this.prevPage,
                setPage:  this.setPage,
                setPageLimit: this.setPageLimit,
                toggleSort: this.toggleSort,
            };
        },

        /* ----------------- *\
         *   ROW SELECTION   *
        \* ----------------- */

        clearSelection () {
            this.list.selectedRecords = [];
        },

        checkPageSelection () {
            let selectedCount = 0;
            const selectedMap = {};

            for (let k = 0; k < this.list.selectedRecords.length; k++) {
                selectedMap[this.list.selectedRecords[k].id] = this.list.selectedRecords[k];
            }

            for (let i = 0; i < this.list.records.length; i++) {
                if (selectedMap[this.list.records[i].id]) {
                    selectedCount += 1;
                }
            }

            const selectToggle = !!(selectedCount === this.list.records.length);

            this.list.selectToggle = selectToggle;

            return this.list.selectToggle;
        },

        togglePageSelection () {
            let selectedCount = 0;
            const selectedMap = {};

            for (let k = 0; k < this.list.selectedRecords.length; k++) {
                selectedMap[this.list.selectedRecords[k].id] = this.list.selectedRecords[k];
            }

            for (let i = 0; i < this.list.records.length; i++) {
                if (selectedMap[this.list.records[i].id]) {
                    selectedCount += 1;
                }
            }

            const mustSelect = (selectedCount < this.list.records.length);

            for (let j = 0; j < this.list.records.length; j++) {
                const record = this.list.records[j];
                if (mustSelect) {
                    if (!selectedMap[record.id]) {
                        selectedMap[record.id] = record;
                        this.list.selectedRecords.push(record);
                    }
                } else {
                    let l = 0;
                    while (l < this.list.selectedRecords.length) {
                        if (this.list.selectedRecords[l].id === record.id) {
                            this.list.selectedRecords.splice(l, 1);
                        } else {
                            l += 1;
                        }
                    }
                }
            }
        },
    },
};

<script>
    import ArchiveWarningModal from '../components/ArchiveWarningModal.vue';

    export default {
        name: 'SodaArchiveWarningModal',
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            proceed: {
                type: Function,
                required: true,
            },
            confirm: {
                type: Object,
                required: true,
            },
            close: {
                type: Function,
                required: true,
            },
        },
        components: {
            'archive-warning-modal': ArchiveWarningModal,
        },
    };
</script>
<template>
    <archive-warning-modal
        :show='show'
        :proceed='proceed'
        :confirm='confirm'
        :close='close'
    >
        <p> {{ $t('p-confirm-archive-soda-a') }}</p>
    </archive-warning-modal>
</template>

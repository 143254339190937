import loader from '@/loader.js';
import store from '@/store';
import notify from '@/notify.js';
import { t } from '@/i18n.js';

const ERROR = 'error';
const INFO = 'info';

export async function onlyUnauthenticatedCheck (to, from, next)  {
    // in those routes, only accept unauthenticated visitors
    try {
        await store.dispatch('checkSession');
    } catch (e) {
        return next();
    }

    next('/');
}

function preventNavigation ({ next, loadId, message, type = INFO }) {
    if (message) notify[type](t(message));
    loader.stop(loadId);
    return next({ name: 'index' });
}

async function preventNavigationAndLogOut ({ to, next, loadId, message, type = INFO }) {
    localStorage.setItem('redirect', to.fullPath);
    await store.dispatch('logout');
    return preventNavigation({ next, loadId, message, type });
}

/* Check user access and roles for restricted route */
export const authGuard = async (to, from, next) => {
    const loadId = loader.start();

    // 1. Check is there is a session
    try {
        await store.dispatch('checkSession');
    } catch (e) {
        let message;
        let type;
        switch (e) {
            case 'No current user':
                message = 'info-must-be-logged-in';
                type = INFO;
                break;
            case 'Refresh Token has expired':
                // Don't display unknown error when refresh token has expired and go straight to login page
                break;
            default:
                message = 'err-unknown';
                type = ERROR;
        }
        return preventNavigationAndLogOut({
            to,
            next,
            loadId,
            message,
            type,
        });
    }

    // 2. Get the user
    try {
        // in specific cases (ex: login), we load the user twice at the same time (redirect the user + checking his accesses)
        // most likely, there will be no change, so we use the cached user if there is any
        await store.dispatch('loadUserCached');
    } catch (e) {
        let message;
        switch (e) {
            case 'invalidUserConfiguration':
                message = 'err-login-invalid-user-configuration';
                break;
            default:
                message = 'err-unknown';
        }
        return preventNavigationAndLogOut({
            to,
            next,
            loadId,
            message,
            type: ERROR,
        });
    }

    // 3. Check if the user has access to the requested page
    const organizationId = to.params.organizationId;
    const resellerId = to.params.resellerId;

    // 3.1 check if user can access the entity
    const entityType = to.meta.authGuard.entityType;
    if (
            entityType &&
            (
                (entityType === 'organization' && !store.state.user.organizationIds.includes(organizationId)) ||
                (entityType === 'reseller' && !store.state.user.resellerIds.includes(resellerId))
            )
        ) {
        return preventNavigation({
            to,
            next,
            loadId,
            message: 'err-restricted-access',
        });
    }

    // 3.2 check restricted access based on user type (role) for organization
    // set authGuard.isCurrentOrgAdmin on meta to reuse it easily in component via $route
    to.meta.authGuard.isCurrentOrgAdmin = store.state.user.organizations.some(org => org.organizationId === organizationId && ['admin', 'support'].includes(org.role));

    const restrictedToOrgRole = to.meta.authGuard.restrictedToOrgRole;
    if (
        restrictedToOrgRole &&
        restrictedToOrgRole === 'admin' &&
        !to.meta.authGuard.isCurrentOrgAdmin
    ) {
        return preventNavigation({
            to,
            next,
            loadId,
            message: 'err-restricted-access',
        });
    }

    next();
    loader.stop(loadId);
};

<script>
    export default {
        name: 'SodaTermsEn',
        props: {
            isDirectCustomer: Boolean,
        },
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Service Description
        </h3>

        <p>
            Codabox's SODA service involves pouring the payroll entries from the various social secretariats into a standardized digital XML format (developed and registered by Codabox), allowing you to easily import them directly into your accounting software.
            You also receive analytical information and any corrections.
            Thanks to this automation, collecting and individually encoding accounting documents sent separately by the social secretariat is a thing of the past.
        </p>

        <p>
            <a href='https://www.codabox.com/fr/services/soda/secretariats-sociaux-partenaires/' target='_blank'>List of member social secretariats</a>
        </p>

        <p>
            The SODA files are imported into your accounting software on a regular basis as miscellaneous operations.
            Note that the SODA files are intended for accounting breakdown and therefore do not contain billing information between the social secretariat and the company.
        </p>

        <p>
            <a href='https://www.codabox.com/fr/services/soda/softwares-prets-pour-lintegration/' target='_blank'>List of compatible accounting software programs</a>
        </p>

        <p>
            Via MyCodabox, you can order SODA and track the status of your applications.
            To activate SODA, there must be at least one active CODA mandate for the company.
        </p>

        <h3>Pricing</h3>

        <h4 class='cb-service-title'>
            Administrative costs
        </h4>

        <p v-if='isDirectCustomer'>
            24 € per SODA subscription (after receipt of first file)
        </p>
        <p v-else>
            When a SODA mandate is activated, upon first receipt of a file for this mandate, you will be billed a one-time fee of 6,00 € per SODA mandate.
        </p>

        <h4 class='cb-service-title'>
            Recurrent costs
        </h4>

        <template v-if='isDirectCustomer'>
            <table class='table'>
                <tbody>
                    <tr>
                        <td>> 2 active SODA subscriptions</td>
                        <td>7,73 €/month/subscription</td>
                    </tr>
                    <tr>
                        <td>1 - 2 active SODA subscriptions</td>
                        <td>9,62 €/month/subscription</td>
                    </tr>
                </tbody>
            </table>
            <p>
                If you manage multiple companies under one Codabox contract, the total number of SODA subscriptions determines the pricing for the recurring costs.
            </p>
            <p class='note'>
                <span class='fa fa-info-circle'></span>
                SODA remains active until you archive the service via MyCodabox, or the mandate is discontinued.
            </p>
        </template>

        <p v-if='!isDirectCustomer'>
            Once the SODA service is activated for your trustee you will be billed monthly a fee of 68,79 €, irrespective of the number of SODA mandates requested.
        </p>

        <h3>General Terms and Conditions</h3>
        <p v-if='isDirectCustomer'>
            <a href='/static/pdfs/20250101 Overeenkomst CodaBox Business CODA-SODA-CARO_NL.pdf' target='_blank'>Overeenkomst Codabox Business (available in Dutch)</a><br>
            <a href='/static/pdfs/20250101 Contrat CodaBox Business CODA-SODA-CARO_FR.pdf' target='_blank'>Contrat Codabox Business (available in French)</a>
        </p>
        <p v-else>
            Terms and conditions of the service:
            <a href='/static/pdfs/MyCodabox.com 202501 CB-Fidu FR-Annex SODA (B2-C2-D).pdf' target='_blank'>FR </a>
            <a href='/static/pdfs/MyCodabox.com 202501 CB-Fidu NL-Annex SODA (B2-C2-D).pdf' target='_blank'>NL</a>
        </p>
    </div>
</template>

<style lang='scss' scoped>
    h3.cb-service-main-title {
        margin-top: 10px;
    }
    h4.cb-service-title {
        text-decoration: underline;
    }
    p.note {
        font-style: italic;
    }
    p.note .fa-info-circle {
        font-size: 16px;
    }
    p, table {
        margin: 10px 0;
    }
    table {
        table-layout: fixed;
    }
    tbody {
        color: $primary-color-dark;
    }
</style>

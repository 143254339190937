import store from '@/store';
import bus from '@/bus.js';
import 'whatwg-fetch';

export async function authFetch (url, opts) {
    if (store.state.token && store.state.idToken) {
        opts.headers = {
            ...opts.headers,
            ...{
                Authorization: 'Bearer ' + store.state.token,
                'ID-TOKEN': store.state.idToken,
            },
        };
    }
    const response = await fetch(url, opts);
    if (response.status === 401) {
        try {
            await store.dispatch('checkSession');
            return authFetch(url, opts);
        } catch (err) {
            bus.emit('session-expired', null);
            throw err;
        }
    } else {
        return response;
    }
}

<script>
    import { gql } from '@apollo/client/core';
    import validate from '../validate.js';
    import notify from '../notify';

    import ListView         from '../components/ListView.js';
    import Exporter         from '../components/Exporter.vue';
    import ClientLink       from '../components/ClientLink.vue';
    import Sodastate        from '../components/Sodastate.vue';
    import ArchiveModal     from '../components/SodaArchiveWarningModal.vue';
    import ArchiveButton    from '../components/ArchiveButton.vue';
    import ContentBox        from '../components/ContentBox.vue';
    import CustomTitle from '@/components/Title';
    import { dateFormat } from '@/filters';
    import ProductPageButton from '@/components/ProductPageButton.vue';
    import { t } from '@/i18n';
    import SearchInput from '@/components/SearchInput';
    import Dropdown from '@/components/DropdownV2';
    import DropdownItem from '@/components/DropdownItem';

    export default {
        name: 'SodaSearch',
        mixins: [ListView],
        props: {
            accountant: {
                type: Object,
            },
            currentEnvironment: {
                type: Object,
            },
        },
        components: {
            ProductPageButton,
            exporter:      Exporter,
            clientlink:    ClientLink,
            sodastate:     Sodastate,
            'modal-archive': ArchiveModal,
            archivebutton: ArchiveButton,
            ContentBox,
            CustomTitle,
            SearchInput,
            Dropdown,
            DropdownItem,
        },
        data () {
            const data = this.listViewData({
                apiModel: 'bff/api/search/fiduciary/social-mandates',
                defaultSortKey: 'client__client_code',
                archival: {
                    showWarning: false,
                    confirm: { confirmed: false },
                    mandateId: null,
                },
                defaultFilters: { client__state: 'active' },
                loaded: false,
            });

            this.configureSelectFilter(data, 'state', [
                {
                    value: 'allstates',
                    label: 'opt-mandate-all',
                    filter: null,
                }, {
                    value: 'not-signed',
                    label: 'lbl-soda-not-signed',
                    filter: { state: 'not-signed' },
                }, {
                    value: 'signed',
                    label: 'lbl-soda-signed',
                    filter: { state: 'signed' },
                }, {
                    value: 'requested',
                    label: 'lbl-soda-requested',
                    filter: { state: 'requested' },
                }, {
                    value: 'delayed',
                    label: 'lbl-soda-delayed',
                    filter: { state: 'delayed' },
                }, {
                    value: 'confirmed',
                    label: 'lbl-soda-confirmed',
                    filter: { state: 'confirmed' },
                }, {
                    value: 'active',
                    label: 'lbl-soda-active',
                    filter: { state: 'active' },
                }, {
                    value: 'archived',
                    label: 'lbl-soda-archived',
                    filter: { state: 'archived' },
                }, {
                    value: 'problem',
                    label: 'lbl-soda-problem',
                    filter: { state: 'problem' },
                },
            ]);

            const _offices = [{
                value: 'alloffices',
                label: 'opt-office-all',
                filter: null,
            }];

            this.configureSelectFilter(data, 'offices', _offices);

            return data;
        },
        watch: {
            'currentEnvironment.id': function () {
                this.search();
            },
        },
        async mounted () {
            if (this.accountant.hasCodaOnlyContract) {
                // user can't access this page for this environment
                notify.error(t('err-restricted-access'));
                this.$router.push({ name: 'index' });
            } else {
                this.watchFilters();
                await this.setSocialOfficesFilterValues();
                this.loaded = true;
                this.search();
            }
        },
        methods: {
            dateFormat,
            dynamicSearchParams () {
                return { fiduciaryIds: [this.currentEnvironment.id] };
            },
            doArchiveWithWarning (id) {
                this.archival.mandateId = id;
                this.archival.confirm.confirmed = false;
                this.archival.showWarning = true;
            },
            async doArchive () {
                if (!this.archival.confirm.confirmed) {
                    return;
                }
                this.archival.showWarning = false;
                try {
                    const { data } = await this.$apollo.mutate({
                        mutation: gql`mutation ($mandateId: String) {
                            archiveSodaMandate(sodaMandateId: $mandateId) {
                                errors { code, detail, source { pointer } }
                            }
                        }`,
                        variables: { mandateId: this.archival.mandateId },
                    });
                    if (data.archiveSodaMandate.errors) {
                        validate.notifyErrors(data.archiveSodaMandate);
                    } else {
                        this.$router.push(`/organization/${this.$route.params.organizationId}/environment/${this.$route.params.environmentId}/archived/soda/${this.archival.mandateId}/`);
                    }
                    return;
                } catch (err) {
                    notify.error(this.$t('err-unknown'));
                    return;
                }
            },
            cancelArchive () {
                this.archival.showWarning = false;
                this.archival.mandateId = null;
            },
            async getSupportedSocialOffices () {
                const { data } = await this.$apollo.query({
                    query: gql`
                      query SupportedSocialOffices {
                        getSupportedSocialOffices {
                            socialWelfareId,
                            name,
                        }
                    }`,
                });

                return data.getSupportedSocialOffices;
            },
            async setSocialOfficesFilterValues () {
                const offices = await this.getSupportedSocialOffices();

                for (const office of offices) {
                    this.list.filters.offices.options.push({
                        value: office.socialWelfareId,
                        label: office.name,
                        filter: { social_welfare__social_welfare_id: office.socialWelfareId },
                    });
                }
            },
        },
    };
</script>

<template>
    <div v-if='loaded'>
        <modal-archive
            :show='archival.showWarning'
            :proceed='doArchive'
            :confirm='archival.confirm'
            :close='cancelArchive'
        />

        <div class='cb-fidu-title'>
            <div class='cb-fidu-actions flex'>
                <div v-if='!accountant.isDirectCustomer' class='mr-3'>
                    <ProductPageButton />
                </div>
                <exporter model='soda-mandates' :label='$t("btn-export-sodas")' :fiduciary-id='currentEnvironment.id' />
            </div>
            <CustomTitle class='mt-6 mb-6'>
                {{ $t('h-soda-mandates') }}
            </CustomTitle>
        </div>

        <content-box>
            <div @submit.prevent.stop class='flex'>
                <SearchInput v-model='list.textsearch' class='mr-3' />

                <Dropdown
                    v-model='list.filters.state.selected'
                    class='mr-3'
                    default-value='allstates'
                    direction='right'
                >
                    <DropdownItem
                        v-for='option in list.filters.state.options'
                        :id='option.value'
                        :name='$t(option.label)'
                        :key='option.value'
                        class='whitespace-nowrap'
                    >
                        {{ $t(option.label) }}
                    </DropdownItem>
                </Dropdown>

                <Dropdown
                    v-model='list.filters.offices.selected'
                    default-value='allstates'
                    direction='right'
                >
                    <DropdownItem
                        v-for='option in list.filters.offices.options'
                        :id='option.value'
                        :name='$t(option.label)'
                        :key='option.value'
                        class='whitespace-nowrap'
                    >
                        {{ $t(option.label) }}
                    </DropdownItem>
                </Dropdown>
            </div>
            <pagination
                :list='list'
                :pagination='pagination'
                :ctrls='getControls()'
                size='small'
                class='my-6'
            />

            <div class='cb-bordered' :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table cb-searchresults'>
                    <colgroup>
                        <collumn class='cb-col-md' :sorting='sorting' skey='client__client_code' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='client__name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='social_welfare__name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='state_modified_at' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='state' />
                        <col class='cb-col-sm'>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ $t('th-client-code') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client__client_code' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client__name' />
                            </th>
                            <th>
                                <span>{{ $t('th-social-office') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='social_welfare__name' />
                            </th>
                            <th>
                                <span>{{ $t('th-state-date') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state_modified_at' />
                            </th>
                            <th>
                                <span>{{ $t('th-state') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state' />
                            </th>
                            <th>
                                <span>{{ $t('th-actions') }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='record in list.records'>
                            <td>
                                <clientlink :client='record.client' document='soda' />
                            </td>
                            <td>{{ record.client.name }}</td>
                            <td>{{ record.social_welfare.name }}</td>
                            <td>{{ dateFormat(record.state_modified_at) }}</td>
                            <td><sodastate :mandate='record' /></td>
                            <td>
                                <archivebutton
                                    :mandate='record'
                                    :archive='doArchiveWithWarning'
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <listloader :list='list' />

            <div class='text-center' v-if='!list.loading'>
                <pagination
                    :list='list'
                    :pagination='pagination'
                    :ctrls='getControls()'
                    scroll='scrollTop'
                    no-margin
                />
            </div>
        </content-box>
    </div>
</template>

<script setup>
import CodaSignaturePopup from '@/components/CodaSignaturePopup.vue';
import CodaSignaturePopupDone from '@/components/CodaSignaturePopupDone.vue';
import { ref, watch } from 'vue';

const currentMandateIndex = ref(-1);
const currentMandate = ref(null);

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    clientV2: {
        type: Object,
        required: true,
    },
    mandates: {
        type: Object,
        required: true,
    },
    isDirectCustomer: {
        type: Boolean,
        required: true,
    },
});
const emits = defineEmits(['close']);

watch(() => props.show, (shown) => {
    currentMandateIndex.value = shown ? 0 : -1;
});

watch(() => currentMandateIndex.value, (index) => {
    if (currentMandateIndex.value < 0 || currentMandateIndex.value >= props.mandates.length) {
        currentMandate.value = null;
        return;
    }

    currentMandate.value = props.mandates[currentMandateIndex.value];
});

function closeSignaturePopup (force) {
    if (force) {
        emits('close');
        // we don't set currentMandateIndex.value to -1, because it can take a while before we get closed.
        // and we do not want to show the CodaSignaturePopupDone
        return;
    }
    if (currentMandateIndex.value + 1 < props.mandates.length) {
        currentMandateIndex.value += 1;
    } else {
        currentMandateIndex.value = -1;
    }
}

</script>

<template>
    <CodaSignaturePopup
        v-if='currentMandate'
        :mandate='currentMandate'
        :client-v2='clientV2'
        :close='closeSignaturePopup'
        :show='show && currentMandateIndex >= 0'
        next-button
    />
    <CodaSignaturePopupDone
        :close='() => emits("close")'
        :show='show && currentMandateIndex < 0'
        :is-direct-customer='props.isDirectCustomer'
    />
</template>

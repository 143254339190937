<template>
    <ul class='secondary-nav'>
        <slot></slot>
    </ul>
</template>

<script setup>
</script>

<style lang='postcss' scoped>
.secondary-nav {
    @apply list-none flex m-0 p-0;
}
</style>

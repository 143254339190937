<script>
    export default {
        props: {
            thinner: Boolean,
            alignLeft: Boolean,
            noMargin: Boolean,
        },
    };
</script>

<template>
    <div class='button-bar' :class='{thinner:thinner, alignLeft:alignLeft, noMargin:noMargin}'>
        <slot></slot>
    </div>
</template>

<style scoped>
.button-bar {
    margin-top: 50px;
    margin-bottom: 50px;
}
.button-bar.thinner {
    margin-top: 40px;
    margin-bottom: 40px;
}
.button-bar.alignLeft {
    text-align: left;
}
.button-bar.noMargin {
    margin-top: 0;
    margin-bottom: 0;
}
</style>

<script setup>
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import Popup from '@/clientcomponents/Popup.vue';
import Translated from '@/components/Translated.vue';
import { computed } from 'vue';

const emits = defineEmits(['hasClickedDone']);
const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    close: {
        type: Function,
        required: true,
    },
    isDirectCustomer: {
        type: Boolean,
        required: true,
    },
});

const ready = () => {
    emits('hasClickedDone');
    props.close();
};

const accountantArticleId = '75000055221';
const directCustomerArticleId = '75000126343';
const faqArticleId = computed(() => {
    return props.isDirectCustomer ? directCustomerArticleId : accountantArticleId;
});

</script>

<template>
    <Popup
        :show='props.show'
        :close='props.close'
        small
    >
        <template #header>
            <h1 class='text-4xl'>
                {{ $t("lbl-congrats") }}
            </h1>
        </template>

        <template #default>
            <div class='flex flex-row items-center'>
                <img class='w-32 mx-4' src='@/assets/party.png' alt='congrats'>
                <div class='max-w-66'>
                    <Translated>
                        <template #en>
                            <p>
                                The signed mandates will be processed in the next few days.
                                Afterwards, you will receive your first CODA.
                                Would you like to follow the status? On <a :href='`https://faq.codabox.com/en/support/solutions/articles/${faqArticleId}`' target='_blank'>this page</a>, you will find more information on the meaning of each status.
                            </p>
                        </template>
                        <template #nl>
                            <p>
                                De ondertekende volmachten worden in de komende dagen verwerkt.
                                Daarna zal je je eerste CODA ontvangen.
                                Volg je de status graag mee op? Op <a :href='`https://faq.codabox.com/nl/support/solutions/articles/${faqArticleId}`' target='_blank'>deze pagina</a> vind je meer informatie over de betekenis van elke status.
                            </p>
                        </template>
                        <template #fr>
                            <p>
                                Les mandats signés seront traités dans les prochains jours.
                                Après, vous recevrez votre premier CODA.
                                Vous voulez suivre l'évolution de l'état ? Sur <a :href='`https://faq.codabox.com/fr/support/solutions/articles/${faqArticleId}`' target='_blank'>cette page</a>, vous trouverez plus d'informations sur la signification des différents états.
                            </p>
                        </template>
                    </Translated>
                </div>
            </div>
        </template>

        <template #buttons>
            <FriendlyButton
                id='doneButton'
                :label='$t("lbl-step-done")'
                type='submit'
                square
                extra-small
                no-margin
                symbol='check'
                :action='ready'
            />
        </template>
    </Popup>
</template>

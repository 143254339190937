<script setup>
import FriendlyButton from '@/clientcomponents/FriendlyButton';
import Icon from '@/components/Icon';

const emits = defineEmits(['click']);

const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: undefined,
    },
    label: {
        type: String,
        default: 'btn-next',
    },
    icon: {
        type: String,
    },
});

function handleClick () {
    emits('click');
}
</script>

<template>
    <div>
        <FriendlyButton
            :label='label'
            no-margin
            square
            small
            :loading='props.loading'
            :disabled='props.disabled'
            :type='type'
            @click.prevent='handleClick'
        >
            <template #iconRight v-if='icon'>
                <Icon
                    :name='icon'
                    class='text-white w-7 h-7 -mr-1'
                />
            </template>
        </friendlybutton>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
const props = defineProps({
    bordered: Boolean,
});

const classes = computed(() => {
    if (props.bordered) {
        return 'border border-r-0 border-y-0 border-grey-200 pl-8';
    } else {
        return 'bg-white rounded-lg pt-6 pb-10 px-8';
    }
});
</script>

<template>
    <div :class='classes'>
        <slot></slot>
    </div>
</template>

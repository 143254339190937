<template>
    <transition name='wizard' mode='out-in'>
        <slot></slot>
    </transition>
</template>

<style scoped>
.wizard-enter-active, .wizard-leave-active {
  transition: opacity .15s;
}
.wizard-enter, .wizard-leave-to /* .wizard-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script setup>
import { ref, defineEmits } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useMutation } from '@vue/apollo-composable';
import CustomTitle from '@/components/Title';
import ContentBox from '@/components/ContentBox';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import { Form } from 'vee-validate';
import FormInput from '@/components/FormInput';
import notify from '@/notify';
import FormToggle from '@/components/FormToggle.vue';
import { t } from '@/i18n';
import Translated from '@/components/Translated';
import validate from '@/validate';
import InfoBanner from '@/components/InfoBanner';
import InfoBannerItem from '@/components/InfoBannerItem';

const props = defineProps({
    organization: Object,
});

const emit = defineEmits(['update:organization']);

const edit = ref(false);
const form = ref(null);
const organizationLinkedClient = ref(false);

const handleEdit = () => {
    edit.value = true;
};

const handleCancel = () => {
    form.value.resetForm();
    edit.value = false;
};

const updateOrganizationSubmit = async (values) => {
    // Do not submit readonly values to graphql because they are not expected in request payload
    delete values.enterpriseNumber;
    delete values.hasBelgianVatNumber;
    delete values.vatNumber;

    try {
        const { mutate: updateOrganization } = await useMutation(gql`
            mutation UpdateOrganization($organizationId: String, $input: UpdateOrganizationInput) {
                updateOrganization(organizationId: $organizationId, input: $input) {
                    errors { code, detail, source { pointer } }
                    organization {
                        id
                        enterpriseName
                        enterpriseNumber
                        hasBelgianVatNumber
                        vatNumber
                        address
                        address2
                        zip
                        city
                        representativeName
                        representativeFunction
                        invoicingEmailAddress
                        delegatesMandateDelivery
                        fiduciaries {
                            id
                            name
                        }
                    }
                }
            }
        `);
        const result = (await updateOrganization({ organizationId: props.organization.id, input: values })).data.updateOrganization;

        if (result.errors?.length > 0) {
            validate.reportGQLFieldErrors(result.errors, form);
        }

        emit('update:organization', result.organization);
        notify.success(t('suc-organization-updated'));

        edit.value = false;
    } catch (e) {
        notify.error(t('err-unknown'));
    }
};

async function getOrganizationLinkedClient () {
    return new Promise((resolve, reject) => {
        const { result, onResult, onError } = useQuery(gql`
            query Clients($input: ClientSearchInput) {
                clients(input: $input) {
                    results {
                        id
                        fiduciaryId,
                    }
                }
            }
        `, {
            input: {
                enterpriseNumbers: [props.organization.enterpriseNumber],
            },
        });

        onResult(() => {
            if (result.value.clients.results.length > 0) {
                resolve(result.value.clients.results[0]);
            } else {
                resolve(null);
            }
        });

        onError((error) => {
            notify.error(t('err-unknown'));
            reject(error);
        });
    });
}

function getClientRouterLink () {
    return `#/organization/${props.organization.id}/environment/${organizationLinkedClient.value.fiduciaryId}/client/${organizationLinkedClient.value.id}/`;
}

async function init () {
    try {
        // does this organization have itself has a client ?
        organizationLinkedClient.value = await getOrganizationLinkedClient();
    } catch {
        notify.error(t('err-unknown'));
    }
}

init();
</script>

<template>
    <Form
        ref='form'
        @submit='updateOrganizationSubmit'
    >
        <header class='flex justify-between'>
            <CustomTitle class='mt-6 mb-6'>
                {{ $t('h-organization-information') }}
            </CustomTitle>
            <div class='flex align-center'>
                <template v-if='!edit'>
                    <FriendlyButton
                        label='lbl-org-info-edit'
                        symbol='pencil'
                        :action='handleEdit'
                        square
                        extra-small
                        no-margin
                        id='org-information-edit'
                    />
                </template>
                <template v-else>
                    <FriendlyButton
                        label='btn-cancel'
                        type='reset'
                        :action='handleCancel'
                        symbol='times'
                        secondary
                        square
                        extra-small
                        no-margin
                        class='mr-2'
                    />
                    <FriendlyButton
                        label='btn-save'
                        type='submit'
                        symbol='check'
                        square
                        extra-small
                        no-margin
                    />
                </template>
            </div>
        </header>
        <InfoBanner class='mb-6'>
            <InfoBannerItem v-if='organizationLinkedClient && edit'>
                <Translated>
                    <template #en>
                        <p>
                            There’s <a :href='getClientRouterLink()' class='inline'>a company</a> you manage with the same enterprise number as your organization. Please edit the company info separately if necessary.
                        </p>
                    </template>
                    <template #nl>
                        <p>
                            Er is <a :href='getClientRouterLink()' class='inline'>een dossier</a> dat je beheert met hetzelfde ondernemingsnummer als je organisatie. Werk de dossierinfo apart bij indien nodig.
                        </p>
                    </template>
                    <template #fr>
                        <p>
                            Il y a <a :href='getClientRouterLink()' class='inline'>un dossier</a> que vous gérez avec le même numéro d'entreprise que votre organisation. Veuillez éditer les infos du dossier séparément si nécessaire.
                        </p>
                    </template>
                </Translated>
            </InfoBannerItem>
        </InfoBanner>
        <content-box>
            <CustomTitle :level='2' no-default-margins>
                {{ $t("ttl-organization-info-general") }}
            </CustomTitle>
            <div class='grid grid-cols-2 gap-6 mt-6'>
                <div>
                    <div class='grid grid-cols-1 gap-3'>
                        <FormInput
                            :value='organization.enterpriseName'
                            name='enterpriseName'
                            ref='enterpriseName'
                            :label='$t("lbl-enterprise-name")'
                            :placeholder='$t("lbl-enterprise-name")'
                            :edit='edit'
                            rules='required|max:160'
                            id='enterpriseName'
                        />
                        <FormInput
                            :value='organization.enterpriseNumber'
                            :label='$t("lbl-enterprise-number")'
                            id='enterpriseNumber'
                            name='enterpriseNumber'
                        />
                        <FormToggle
                            name='hasBelgianVatNumber'
                            :value='organization.hasBelgianVatNumber'
                            :label='$t("lbl-has-belgian-vat-number")'
                            disabled
                            id='hasBelgianVatNumber'
                            class='my-2'
                        />
                        <FormInput
                            :value='organization.vatNumber'
                            :label='$t("lbl-vat-number")'
                            id='vatNumber'
                            name='vatNumber'
                        />
                        <FormInput
                            :value='organization.representativeName'
                            name='representativeName'
                            :label='$t("lbl-representative-name")'
                            :placeholder='$t("placeholder-representative-name")'
                            rules='required|max:160|composedName'
                            :edit='edit'
                            id='representativeName'
                        />
                        <FormInput
                            :value='organization.representativeFunction'
                            name='representativeFunction'
                            :label='$t("lbl-representative-function")'
                            :placeholder='$t("lbl-representative-function")'
                            :edit='edit'
                            id='representativeFunction'
                            rules='required|max:128'
                        />
                    </div>
                </div>
                <div>
                    <div class='grid grid-cols-1 gap-3'>
                        <FormInput
                            :value='organization.address'
                            name='address'
                            :label='$t("lbl-address")'
                            :placeholder='$t("lbl-address-line-1")'
                            rules='required|max:108'
                            :edit='edit'
                            id='address'
                        />
                        <FormInput
                            :value='organization.address2'
                            name='address2'
                            rules='max:100'
                            :placeholder='$t("lbl-address-line-2")'
                            :edit='edit'
                            id='address2'
                        />
                        <FormInput
                            :value='organization.zip'
                            name='zip'
                            :label='$t("lbl-zip")'
                            :placeholder='$t("lbl-zip")'
                            rules='required|min:4|max:20'
                            :edit='edit'
                            id='zip'
                        />
                        <FormInput
                            :value='organization.city'
                            name='city'
                            :label='$t("lbl-city")'
                            :placeholder='$t("lbl-city")'
                            rules='required|max:50'
                            :edit='edit'
                            id='city'
                        />
                    </div>
                </div>
            </div>
            <hr class='my-8 h-px border-grey-50 bg-grey-50'>
            <CustomTitle :level='2' no-default-margins>
                {{ $t("ttl-organization-info-invoicing") }}
            </CustomTitle>
            <div class='grid grid-cols-2 gap-6 mt-6'>
                <FormInput
                    :value='organization.invoicingEmailAddress'
                    name='invoicingEmailAddress'
                    :label='$t("lbl-invoicing-email-address")'
                    :placeholder='$t("lbl-email")'
                    :edit='edit'
                    id='invoiceEmailAddress'
                    rules='email|required|max:254'
                />
            </div>
        </content-box>
    </Form>
</template>

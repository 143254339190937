<script>
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import CustomTitle from '@/components/Title';
import Tooltip from '@/components/Tooltip';

export default {
    props: {
        title: String,
        save: Function,
        cancel: Function,
        editable: {
            type: Boolean,
            default: false,
        },
        info: String,
    },
    components: {
        FriendlyButton,
        CustomTitle,
        Tooltip,
    },
    data () {
        return {
            editMode: false,
        };
    },
    emits: ['cancel', 'save'],
    methods: {
        edit () {
            this.editMode = true;
        },
        async cancelEdit () {
            if (this.cancel) {
                await this.cancel();
            }
            this.editMode = false;
            this.$emit('cancel');
        },
        async saveEdit () {
            if (this.save) {
                await this.save();
            }
            this.editMode = false;
            this.$emit('save');
        },
    },
};
</script>
<template>
    <section class='content-box'>
        <header class='flex mb-6' v-if='title || $slots.actions'>
            <div class='flex'>
                <CustomTitle :level='2' no-default-margins :style-of='3'>
                    {{ title }}
                </CustomTitle>
                <tooltip faded v-if='info || $slots.info' class='ml-2 mb-auto' right>
                    <template #trigger>
                        <i class='fa fa-info-circle'></i>
                    </template>
                    <template #content>
                        {{ info }}
                        <slot name='info'></slot>
                    </template>
                </tooltip>
            </div>
            <div class='ml-auto'>
                <div v-if='editable'>
                    <template v-if='!editMode'>
                        <FriendlyButton
                            id='voila_delivery_edit'
                            label='btn-edit'
                            :action='edit'
                            extra-small
                            no-margin
                            square
                        />
                    </template>
                    <template v-else>
                        <FriendlyButton
                            id='button-cancel-edit'
                            label='btn-cancel'
                            :action='cancelEdit'
                            extra-small
                            no-margin
                            square
                            class='mr-3'
                        />
                        <FriendlyButton
                            id='voila_delivery_save'
                            label='btn-save'
                            :action='saveEdit'
                            extra-small
                            square
                            no-margin
                        />
                    </template>
                </div>
                <slot name='actions'></slot>
            </div>
        </header>
        <slot :edit='editMode'></slot>
    </section>
</template>

<style lang="postcss" scoped>
.content-box {
    @apply p-6 bg-white rounded-md;
}
</style>

<script setup>
import { ref, computed } from 'vue';
import { useField } from 'vee-validate';
import Tooltip from '@/components/Tooltip';

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: false,
    },
    options: {
        type: Array,
        required: false,
        validator: (options) => {
            /* props.options needs to be in format
            [
                {
                    label: String,
                    value: String,
                }
            ] */
            return options.every(option =>
                option &&
                typeof option === 'object' &&
                'label' in option && typeof option.label === 'string' &&
                'value' in option && typeof option.value === 'string',
            );
        },
    },
    value: {
        type: [String, Number],
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    narrow: {
        type: Boolean,
        default: false,
    },
    rules: {
        type: String,
        default: () => '',
    },
    mode: {
        type: String,
        default: 'eager',
    },
    info: String,
    edit: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: () => '',
        validator: function (value) {
            // allowed sizes
            const allowedValues = ['', 'small'];
            return allowedValues.includes(value);
        },
    },
});

const selectRef = ref(null);

const { value: selectedValue, errorMessage, handleChange, validate: doValidate } = useField(
    () => props.name, // make name reactive (mostly for usage with FieldArray)
    props.rules,
    {
        validateOnValueUpdate: false,
        initialValue: props.value,
        label: props.label,
    });

const emits = defineEmits(['change']);
function onChange (e) {
    if (
        props.mode === 'aggressive' || props.mode === 'eager'
    ) {
        validate(e.target.value);
    }
}

async function validate (value) {
    await doValidate();
    handleChange(value);
    emits('change', value);
}
const required = computed(() => {
    return props.rules.includes('required');
});
</script>

<template>
    <div class='form-select-wrapper' :class='{ narrow }'>
        <label v-if='label' :for='name'>
            <span class='truncate'>
                {{ label }}<span v-if='required && edit' class='required'>*</span>
            </span>
            <span v-if='info' class='ml-2 font-normal'>
                <Tooltip>
                    <template #trigger>
                        <i class='fa fa-info-circle'></i>
                    </template>
                    <template #content>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <div>{{ info }}</div>
                    </template>
                </Tooltip>
            </span>
        </label>
        <div class='form-select' :class='[{ "has-error": !!errorMessage }, size]' v-if='edit'>
            <select
                :name='name'
                :value='selectedValue'
                @change='onChange'
                ref='selectRef'
            >
                <option disabled value='' v-if='placeholder'>
                    {{ placeholder }}
                </option>
                <option v-for='(option, index) in options' :key='index' :value='option.value'>
                    {{ option.label }}
                </option>
                <slot name='options'></slot>
            </select>
            <span class='form-select-arrow text-grey-600'>
                <i class='fa fa-chevron-down'></i>
            </span>
        </div>
        <div v-else>
            {{ value }}
        </div>
        <p class='form-select-errors' v-show='errorMessage'>
            {{ errorMessage }}
        </p>
    </div>
</template>

<style scoped>
.form-select-wrapper {
    display: inline-block;
    width: 100%;
}

.form-select-wrapper.narrow {
    width: 320px;
}

label {
    @apply text-grey-700 m-0 mb-2;
    font-weight: 600;
    transition: color linear 100ms;
}

.form-select-errors {
    @apply text-red-300 ml-0 inline-block font-bold text-sm transition-all mt-1 mb-0 mt-2;
}

.form-select {
    @apply border-solid border-grey-250 border-2 rounded h-12;
    height: 44px;
    transition: border-color linear 100ms;
    position: relative;
    display: flex;
    align-items: center;
}

.form-select.small {
    @apply h-10;
 }

.form-select.small select {
    @apply px-3;
}

.form-select select {
    @apply px-4;
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    -webkit-appearance: none; /* Removes default styling for select in iOS */
    -moz-appearance: none; /* Removes default styling for select in Firefox */
    appearance: none;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #333;
    line-height: 44px;
    cursor: pointer;
}

.form-select select::-ms-expand {
    display: none; /* Hide the default dropdown arrow in IE */
}

.form-select select:focus {
    @apply border-blue-300;
}

.has-error .form-select select {
    @apply border-red-300;
}

.required {
    @apply text-blue-300;
}

/* Style for custom dropdown arrow */
.form-select-arrow {
    position: absolute;
    right: 10px;
    pointer-events: none;
}

/* Additional styles if needed */
</style>

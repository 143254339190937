<script>
import NavBar from '@/components/NavBar.vue';
import store from '@/store';
import { Auth } from '@aws-amplify/auth';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import CustomTitle from '@/components/Title';
import notify from '@/notify.js';
import validate from '@/validate.js';
import { PASSWORD_RULES } from '@/constants/common';
import { Form } from 'vee-validate';
import FormInput from '@/components/FormInput.vue';

export default {
    name: 'ResetPasswordView',
    components: {
        NavBar,
        FriendlyButton,
        FormInput,
        CustomTitle,
        // eslint-disable-next-line vue/no-reserved-component-names
        Form,
    },
    data () {
        return {
            cognitoError: '',
            cognitoErrorRetry: '',
            passwordRules: PASSWORD_RULES,
        };
    },
    watch: {
        '$store.state.maintenance': function () {
            store.dispatch('logout');
        },
    },
    methods: {
        isNewPasswordStrong (newPassword) {
            // TODO: better to move this as a validation rule
            return validate.isPasswordStrong(newPassword);
        },
        isPasswordResetSubmitDisabled (newPassword) {
            return (!newPassword || !this.isNewPasswordStrong(newPassword));
        },
        checkPasswordRule (rgx, newPassword) {
            return validate.checkPasswordRule(rgx, newPassword);
        },
        async resetPassword (values) {
            if (!this.isNewPasswordStrong(values.newPassword)) {
                this.$refs.resetPasswordForm.setErrors({
                    password: [this.$t('val-password-not-strong')],
                });
                return;
            }

            try {
                const email = this.$route.query.email;
                await Auth.forgotPasswordSubmit(email, this.$route.query.code, values.newPassword);
                notify.success(this.$t('suc-reset-password'));
                this.$router.push({ name: 'login', query: { email } });
            } catch (e) {
                if (e.message.includes('Invalid verification code') || e.message.includes('Invalid code')) {
                    this.cognitoError = this.$t('err-password-reset-code-invalid-expired');
                    this.cognitoErrorRetry = this.$t('err-password-reset-code-new-link');
                } else {
                    this.cognitoError = this.$t('err-unknown-retry-later');
                }
            }
        },
    },
};

</script>

<template>
    <div class='reset-password'>
        <NavBar no-nav transparent />
        <div id='reset-password-form' class='reset-password__panel'>
            <div class='w-full mb-12 flex justify-center'>
                <img class='w-56' src='@/assets/mycodabox-logo.svg' alt='Mycodabox&apos;s logo'>
            </div>
            <div class='bg-white shadow-xl drop-shadow-xl py-8 px-12 rounded-xl'>
                <div v-if='cognitoError' class='rounded-md bg-red-300 text-white mb-4 px-6 py-3'>
                    {{ cognitoError }}
                    <a class='text-white underline' v-if='cognitoErrorRetry' href='/#/forgot-password'>
                        {{ cognitoErrorRetry }}
                    </a>
                </div>
                <Form ref='resetPasswordForm' tag='div' @submit='resetPassword' v-slot='{ values }'>
                    <CustomTitle class='m-0' :style-of='3'>
                        {{ $t('h-reset-password') }}
                    </CustomTitle>
                    <FormInput
                        autocomplete='new-password'
                        :label='$t("lbl-new-password")'
                        type='password'
                        name='newPassword'
                        rules='required'
                        mode='aggressive'
                        edit
                    />

                    <div class='mt-6'>
                        <ul class='list-none p-0 text-blue-400'>
                            <li class='list-none' v-for='rule in passwordRules' :key='rule.label'>
                                <span v-if='values.newPassword && values.newPassword.length > 0 && checkPasswordRule(rule.regex, values.newPassword)' class='text-green-300'>
                                    <i class='fa fa-check mr-2'></i>{{ $t(rule.label) }}
                                </span>
                                <span v-else class='text-red-300'>
                                    <i class='fa fa-times mr-2'></i>{{ $t(rule.label) }}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class='mt-6 mb-3'>
                        <FriendlyButton
                            label='btn-reset-password'
                            no-margin
                            fullwidth
                            square
                            small
                            type='submit'
                            :disabled='isPasswordResetSubmitDisabled(values.newPassword)'
                        />
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<style scoped>
.reset-password {
    display: flex;
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 4rem);
}

.reset-password__panel {
    @apply pb-24 my-auto;
    max-width: 430px;
    width: 90%;
}
</style>

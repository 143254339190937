<template>
    <div class='email-pill'>
        <div class='w-full' :class='{"flex": inline}'>
            <div class='email-pill__email'>
                <span class='truncate w-full inline-block'>{{ email }}</span>
            </div>
            <div class='flex' :class='{"ml-3 pl-3 border border-r-0 border-t-0 border-b-0 border-solid": inline}' v-if='verificationState'>
                <Tooltip
                    faded
                    :class='stateColor + "emailVerificationStateTooltip emailVerificationStateLabel"'
                    right
                    min-width-max
                    no-wrap
                >
                    <template #trigger>
                        <span :class='`${stateColor} mr-2`'>{{ stateLabel }}</span>
                        <i class='fa fa-info-circle'></i>
                    </template>
                    <template #content>
                        <span class='emailVerificationStateTooltip emailVerificationStateLegend'>{{ verificationStateLegend }}</span>
                    </template>
                </Tooltip>
                <template v-if='!disableActions'>
                    <div class='ml-auto pl-3' v-if='canResend'>
                        <a href='javascript:void(0)' @click.stop='clickResend' class='color-blue-300 ml-auto flex items-center'>
                            {{ $t('btn-open-resend-verification-popup') }}
                            <i class='fa fa-rotate-right ml-2'></i>
                        </a>
                    </div>
                    <div class='ml-auto pl-3' v-if='canSend'>
                        <a href='javascript:void(0)' @click.stop='clickSend' class='color-blue-300 ml-auto flex items-center'>
                            {{ $t('btn-open-send-verification-popup') }}
                            <i class='fa fa-paper-plane ml-2'></i>
                        </a>
                    </div>
                </template>
            </div>
        </div>
        <Popup
            :show='resendPopupOpened'
            :close='closeResendPopup'
            small
            id='client-resend-email-verification-popup'
        >
            <template #header>
                {{ $t('ttl-email-resend-verification') }}
            </template>

            <div>{{ $t("p-email-resend-verification-popup", { email }) }}</div>

            <template #buttons>
                <div class='flex'>
                    <FriendlyButton
                        label='btn-cancel'
                        class='mr-3 ml-auto'
                        :action='closeResendPopup'
                        symbol='times'
                        small
                        no-margin
                        secondary
                    />
                    <FriendlyButton
                        label='btn-submit-resend-verification-popup'
                        small
                        no-margin
                        symbol='check'
                        id='email-pill-email-verification-do-resend'
                        :action='doResendVerification'
                    />
                </div>
            </template>
        </Popup>
        <Popup
            :show='sendPopupOpened'
            :close='closeSendPopup'
            small
            id='client-send-email-verification-popup'
        >
            <template #header>
                {{ $t('ttl-email-send-verification') }}
            </template>

            <div>{{ $t("p-email-send-verification-popup", { email }) }}</div>

            <template #buttons>
                <div class='flex'>
                    <FriendlyButton
                        label='btn-cancel'
                        class='mr-3 ml-auto'
                        :action='closeSendPopup'
                        symbol='times'
                        small
                        no-margin
                        secondary
                    />
                    <FriendlyButton
                        label='btn-submit-send-verification-popup'
                        small
                        no-margin
                        symbol='check'
                        id='email-pill-email-verification-do-resend'
                        :action='doResendVerification'
                    />
                </div>
            </template>
        </Popup>
    </div>
</template>

<script>
import Tooltip from '@/components/Tooltip';
import Popup from '@/clientcomponents/Popup';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import { gql } from '@apollo/client/core';
import notify from '@/notify.js';
import bus from '@/bus.js';

export default {
    name: 'EmailPill',
    props: {
        email: String,
        inline: {
            type: Boolean,
            default: false,
        },
        emailType: String,
        clientId: String,
        bus: Object,
        disableActions: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Tooltip,
        Popup,
        FriendlyButton,
    },
    data () {
        return {
            resendPopupOpened: false,
            sendPopupOpened: false,
            emailVerifications: [],
        };
    },
    computed: {
        stateColor () {
            return {
                'new': '',
                'verification-sent': 'in-progress-color',
                'reminder-sent': 'in-progress-color',
                'failed-expired': 'failed-color',
                'failed-refused': 'failed-color',
                'failed-challenge': 'failed-color',
                'verified-pi-mandate': 'verified-color',
                'verified-email': 'verified-color',
                'verified-migration': 'verified-color',
                'verificationSent': 'in-progress-color',
                'reminderSent': 'in-progress-color',
                'failedExpired': 'failed-color',
                'failedRefused': 'failed-color',
                'failedChallenge': 'failed-color',
                'verifiedPiMandate': 'verified-color',
                'verifiedEmail': 'verified-color',
                'verifiedMigration': 'verified-color',
            }[this.verificationState];
        },
        stateLabel () {
            return this.$t({
                'new': 'lbl-email-verification-state-none',
                'verification-sent': 'lbl-email-verification-state-in-progress',
                'reminder-sent': 'lbl-email-verification-state-in-progress',
                'failed-expired': 'lbl-email-verification-state-failed',
                'failed-refused': 'lbl-email-verification-state-failed',
                'failed-challenge': 'lbl-email-verification-state-failed',
                'verified-pi-mandate': 'lbl-email-verification-state-verified',
                'verified-email': 'lbl-email-verification-state-verified',
                'verified-migration': 'lbl-email-verification-state-verified',
                'verificationSent': 'lbl-email-verification-state-in-progress',
                'reminderSent': 'lbl-email-verification-state-in-progress',
                'failedExpired': 'lbl-email-verification-state-failed',
                'failedRefused': 'lbl-email-verification-state-failed',
                'failedChallenge': 'lbl-email-verification-state-failed',
                'verifiedPiMandate': 'lbl-email-verification-state-verified',
                'verifiedEmail': 'lbl-email-verification-state-verified',
                'verifiedMigration': 'lbl-email-verification-state-verified',
            }[this.verificationState]);
        },
        canResend () {
            return this.verificationState && ![
                'verified-email',
                'verifiedEmail',
                'new',
                'verified-pi-mandate',
                'verified-migration',
                'verifiedPiMandate',
                'verifiedMigration',
            ].includes(this.verificationState);
        },
        canSend () {
            return this.verificationState === 'new';
        },
        verificationState () {
            const emailFound = this.emailVerifications.find(verif => {
                return verif.email.toLowerCase() === this.email.toLowerCase();
            });
            return emailFound ? emailFound.state : null;
        },
        verificationStateLegend () {
            let translations;
            switch (this.emailType) {
                case 'voila':
                    translations = {
                        new: 'lgnd-voila-email-verification-state-none',
                        verificationSent: 'lgnd-voila-email-verification-state-in-progress',
                        reminderSent: 'lgnd-voila-email-verification-state-in-progress',
                        failedExpired: 'lgnd-voila-email-verification-state-failed',
                        failedRefused: 'lgnd-voila-email-verification-state-failed',
                        failedChallenge: 'lgnd-voila-email-verification-state-failed',
                        verifiedPiMandate: 'lgnd-voila-email-verification-state-verified',
                        verifiedEmail: 'lgnd-voila-email-verification-state-verified',
                        verifiedMigration: 'lgnd-voila-email-verification-state-verified',
                    };
                    break;
                default:
                    translations = {
                        new: 'lgnd-email-verification-state-none',
                        verificationSent: 'lgnd-email-verification-state-in-progress',
                        reminderSent: 'lgnd-email-verification-state-in-progress',
                        failedExpired: 'lgnd-email-verification-state-failed',
                        failedRefused: 'lgnd-email-verification-state-failed',
                        failedChallenge: 'lgnd-email-verification-state-failed',
                        verifiedPiMandate: 'lgnd-email-verification-state-verified',
                        verifiedEmail: 'lgnd-email-verification-state-verified',
                        verifiedMigration: 'lgnd-email-verification-state-verified',
                    };
                    break;
            }
            return this.$t(translations[this.verificationState]);
        },
    },
    created: function () {
        this.getEmailVerifications();
        if (this.bus) {
            bus.on('updated', this.getEmailVerifications);
        }
    },
    methods: {
        openResendPopup () {
            this.resendPopupOpened = true;
        },
        closeResendPopup () {
            this.resendPopupOpened = false;
        },
        clickResend () {
            this.openResendPopup();
        },
        async doResendVerification () {
            try {
                await this.$apollo.mutate({
                    mutation: gql`mutation ($input: ResendEmailInput!) {
                        resendEmailVerification(input: $input) {
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            clientId: this.clientId,
                            email: this.email,
                        },
                    },
                });
                notify.success(this.$t('p-email-verification-resend-success'));
                this.getEmailVerifications();
            } catch (e) {
                notify.error(this.$t('p-email-verification-resend-error'));
            }
            this.closeResendPopup();
            this.closeSendPopup();
        },
        async getEmailVerifications () {
            const { data } = await this.$apollo.query({
                query: gql`query EmailVerifications($clientId: String) {
                    emailVerifications(clientId:$clientId) {
                        email,
                        state
                    }
                }`,
                variables: {
                    clientId: this.clientId,
                },
            });

            this.emailVerifications = data.emailVerifications;
        },
        openSendPopup () {
            this.sendPopupOpened = true;
        },
        closeSendPopup () {
            this.sendPopupOpened = false;
        },
        clickSend () {
            this.openSendPopup();
        },
    },
};
</script>

<style lang="postcss" scoped>
.email-pill {
    @apply border border-solid border-grey-200 rounded-md py-2 px-3 inline-block;
}

.email-pill__email {
    @apply font-bold inline-flex;
    max-width: 100%;
}
</style>

<template>
    <div class='wizard-screen'>
        <slot></slot>
    </div>
</template>

<style scoped>
.wizard-screen {
    min-height: 70vh;
    max-width: 1200px;
    margin: 80px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
}
@media (max-width: 1200px) {
    .wizard-screen {
        flex-direction: column;
    }
}
</style>

<script>
    import { gql } from '@apollo/client/core';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import FormSelect from '@/components/FormSelect.vue';
    import { FieldArray } from 'vee-validate';

    export default {
        name: 'ClientAddSoda',
        props: {
            minRequired: {
                type: Number,
                default: 0,
            },
            formRef: Object,
        },
        components: {
            FormSelect,
            FriendlyButton,
            FieldArray,
        },
        data () {
            return {
                supportedSocialOffices: [],
            };
        },
        computed: {
            supportedSocialOfficesAsFormSelectOptions () {
                return this.supportedSocialOffices.map(so => {
                    return {
                        label: so.name,
                        value: so.socialWelfareId,
                    };
                });
            },
        },
        created: async function () {
            await this.getSupportedSocialOffices();
            this.initMinRequired();
        },
        emits: ['input'],
        methods: {
            initMinRequired () {
                for (let i = 0; i < this.minRequired; i++) {
                    this.$refs.socialOfficesFieldArray.push();
                }
            },
            async getSupportedSocialOffices () {
                const { data } = await this.$apollo.query({
                    query: gql`
                      query SupportedSocialOffices {
                        getSupportedSocialOffices {
                            socialWelfareId,
                            name,
                        }
                    }`,
                });

                this.supportedSocialOffices = data.getSupportedSocialOffices;
            },
            hasDuplicatedSocialOffices () {
                let duplicatedFound = false;

                const socialOfficesSelected = this.formRef.values.socialOffices;
                if (socialOfficesSelected) {
                    for (let i = 0; i < socialOfficesSelected.length; i++) {
                        const currentSocialOffice = socialOfficesSelected[i];

                        if (currentSocialOffice) {
                            const isDuplicated = socialOfficesSelected.filter(soSelected => soSelected === currentSocialOffice).length > 1;

                            if (isDuplicated) {
                                duplicatedFound = true;

                                this.formRef.setErrors({
                                    [`socialOffices[${i}]`]: this.$t('val-unique-office'),
                                });
                            }
                        }
                    }
                }

                return duplicatedFound;
            },
        },
    };
</script>
<template>
    <div>
        <FieldArray name='socialOffices' v-slot='{ fields, push, remove }' ref='socialOfficesFieldArray'>
            <div v-for='(field, i) in fields' :key='field.key'>
                <div class='flex items-start mb-3'>
                    <FormSelect
                        :options='supportedSocialOfficesAsFormSelectOptions'
                        :name='`socialOffices[${i}]`'
                        :ref='`socialOffices[${i}]`'
                        :id='`social_offices_${i}`'
                        :rules='`${formRef.values.socialOffices && formRef.values.socialOffices.length > 0 ? "required" : ""}`'
                        :placeholder='$t("lbl-select-office")'
                        narrow
                        edit
                        class='mb-3'
                        size='small'
                    />

                    <FriendlyButton
                        v-if='formRef.values.socialOffices && formRef.values.socialOffices.length > minRequired'
                        symbol='times'
                        @click='remove(i)'
                        micro
                        secondary
                        square
                        no-text
                        no-margin
                        class='ml-2 mt-2'
                        no-border
                        :id='"removeOfficeButton-" + i'
                    />
                </div>
            </div>
            <FriendlyButton
                symbol='plus'
                @click='push()'
                id='addSocialOfficeButton'
                extra-small
                square
                :label='$t("btn-add-office")'
                no-margin
                small-icon
            />
        </FieldArray>
    </div>
</template>

<style lang='scss' scoped>
    .office {
        display: inline-block;
    }
</style>

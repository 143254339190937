<script>
    export default {
        name: 'SortToggle',
        props: {
            sorting: {
                type: Object,
                required: true,
            },
            ctrls: {
                type: Object,
                required: true,
            },
            skey: {
                type: String,
                required: true,
            },
        },
    };
</script>

<template>
    <span
        class='cb-sorttoggle'
        :class='{"active":sorting.key === skey}'
        @click.prevent='ctrls.toggleSort(skey)'
    >
        <span v-if='sorting.key === skey'>
            <span v-if='sorting.dir > 0'>
                <i class='fa fa-sort-desc'></i>
            </span>
            <span v-if='sorting.dir < 0'>
                <i class='fa fa-sort-asc'></i>
            </span>
        </span>
        <span v-if='sorting.key !== skey'>
            <i class='fa fa-sort'></i>
        </span>
    </span>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useField } from 'vee-validate';
import Tooltip from '@/components/Tooltip';

const props = defineProps({
    value: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        default: () => '',
    },
    label: {
        type: String,
    },
    placeholder: {
        type: String,
        default: '',
    },
    narrow: {
        type: Boolean,
        default: false,
    },
    rules: {
        type: String,
        default: () => '',
    },
    mode: {
        type: String,
        default: 'eager',
        validator: (value) => ['lazy', 'eager', 'aggressive'].includes(value),
    },
    info: String,
    edit: {
        type: Boolean,
        default: false,
    },
    formatter: {
        type: Function,
        default: (v) => v,
    },
});

const textareaRef = ref(null);

const { value: inputValue, handleChange, validate, errorMessage } = useField(
    props.name,
    props.rules,
    {
        validateOnValueUpdate: false,
        initialValue: props.value,
        label: props.label,
    });

function onInput (e) {
    if (
        props.mode === 'aggressive' ||
        (
            props.mode === 'eager' &&
            errorMessage.value
        )
    ) {
        validateAndFormat(e.target.value);
    }
}

function onBlur (e) {
    if (props.mode === 'eager') {
        validateAndFormat(e.target.value);
    }
}

async function validateAndFormat (value) {
    handleChange(props.formatter(value));
    await validate();
}

const required = computed(() => props.rules.includes('required'));
</script>

<template>
    <div class='form-textarea-wrapper' :class='{ narrow }'>
        <label v-if='label' for='name' class='flex'>
            <span class='truncate w-100'>
                {{ label }}<span v-if='required && edit' class='required'>*</span>
            </span>
            <span v-if='info' class='ml-2 font-normal'>
                <Tooltip>
                    <template #trigger>
                        <i class='fa fa-info-circle'></i>
                    </template>
                    <template #content>
                        <div>{{ info }}</div>
                    </template>
                </Tooltip>
            </span>
        </label>
        <div class='form-textarea' :class='{ "has-error": !!errorMessage }' v-if='edit'>
            <textarea
                :name='name'
                :id='name'
                :placeholder='placeholder'
                ref='textareaRef'
                :value='inputValue'
                @input='onInput'
                @blur='onBlur'
            ></textarea>
        </div>
        <div v-else>
            {{ value }}
        </div>
        <p class='form-textarea-errors' v-show='errorMessage'>
            {{ errorMessage }}
        </p>
    </div>
</template>

<style scoped lang='postcss'>
.form-textarea-wrapper {
    @apply inline-block w-full;
}

.form-textarea-wrapper.narrow {
    @apply w-80;
}

label {
    @apply text-grey-700 mb-2 font-semibold;
}

.form-textarea {
    @apply border-2 border-solid border-grey-250 rounded transition-colors flex relative;
    min-height: 120px; /* Adjustable height */
}

.form-textarea:focus-within {
    @apply border-blue-300;
}

.form-textarea textarea {
    @apply w-full text-grey-700 p-3 bg-transparent resize-none border-none;
    line-height: 1.5; /* Adjustable line height */
}

.form-textarea textarea:focus {
    @apply outline-none;
}

.form-textarea-errors {
    @apply text-red-300 block font-bold text-sm mt-1;
}

.required {
    @apply text-blue-300;
}

.has-error label {
    @apply text-red-300;
}

.has-error .form-textarea {
    @apply border-red-300;
}

.has-error .form-textarea textarea {
    @apply text-red-300;
}
</style>

import { authFetch as fetch } from './fetch';

export function getEnvVariables () {
    return fetch('/bff/env-variables', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        },
    });
}

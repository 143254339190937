<script>
    export default {
        name: 'SodaState',
        props: {
            mandate: {
                type: Object,
                required: true,
            },
        },
        computed: {
            stateChecked () {
                return this.mandate.state === 'active';
            },
            stateLabel () {
                return this.$t({
                    'not-signed': 'lbl-soda-not-signed',
                    active:     'lbl-soda-active',
                    waiting:    'lbl-soda-waiting',
                    signed:     'lbl-soda-signed',
                    requested:  'lbl-soda-requested',
                    delayed:    'lbl-soda-delayed',
                    confirmed:  'lbl-soda-confirmed',
                    archived:   'lbl-soda-archived',
                    problem:    'lbl-soda-problem',
                }[this.mandate.state]);
            },
            stateLegend () {
                return this.$t({
                    'not-signed': 'lgnd-soda-not-signed',
                    active:     'lgnd-soda-active',
                    waiting:    'lgnd-soda-waiting',
                    signed:     'lgnd-soda-signed',
                    requested:  'lgnd-soda-requested',
                    delayed:    'lgnd-soda-delayed',
                    confirmed:  'lgnd-soda-confirmed',
                    archived:   'lgnd-soda-archived',
                    problem:    'lgnd-soda-problem',
                }[this.mandate.state]);
            },
            stateNote () {
                // Unfortunately, we have 2 different endpoints that return 2 different fields.
                return this.mandate.state_note || this.mandate.stateNote;
            },
        },
    };
</script>

<template>
    <span>
        <span class='cb-state-label' :class='{"ok-color":stateChecked}'>
            {{ stateLabel }}
        </span>
        <div class='cb-legend'>
            <i class='fa fa-info-circle'></i>
            <div class='cb-legend-body'>
                {{ stateLegend }}
            </div>
        </div>
        <div v-if='stateNote' class='cb-legend'>
            <i class='fa fa-exclamation-triangle warning-color'></i>
            <div class='cb-legend-body'>
                {{ stateNote }}
            </div>
        </div>
    </span>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
    sortable: Boolean,
    property: String,
    sortedByProperty: String,
    alignRight: Boolean,
});

const emit = defineEmits(['sort']);
const sortedOrder = ref('asc');

const active = computed(() => {
    return props.sortedByProperty === props.property;
});

const doSort = () => {
    if (props.sortable) {
        sortedOrder.value = sortedOrder.value === 'asc' ? 'desc' : 'asc';
        emit('sort', props.property, sortedOrder.value);
    }
};
</script>

<template>
    <th class='px-5 h-12 font-bold' @click='doSort'>
        <div class='flex items-center' :class='{"cursor-pointer": props.sortable, "justify-end": props.alignRight}'>
            <slot></slot>
            <i
                v-if='props.sortable'
                class='fa text-xs ml-2'
                :class='[active ? "text-grey-700" : "text-grey-250", sortedOrder === "desc" ? "fa-arrow-up" : "fa-arrow-down"]'
            ></i>
        </div>
    </th>
</template>

<script>
    export default {
        name: 'CodaTermsFr',
        props: {
            isDirectCustomer: Boolean,
            hasCodaOnlyContract: Boolean,
        },
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Description du service
        </h3>
        <p>
            Chaque jour, Codabox fournit les extraits de compte électroniques au format CODA directement à votre logiciel
            de comptabilité. Ainsi, vous ne devez plus rechercher vous-même ces informations relatives aux comptes
            bancaires, et vous disposez toujours d'un aperçu facile et actualisé des données financières pour la
            comptabilité.
        </p>
        <p>
            Via MyCodabox, vous pouvez commander les CODA pour les différents comptes auprès de différentes banques, et
            ce pour tous les dossiers dont vous tenez la comptabilité. Nous nous chargeons ensuite du travail
            administratif : la préparation des mandats bancaires corrects, les faire signer par la bonne personne, le
            suivi avec les banques, etc. Vous pouvez suivre l'état des demandes via MyCodabox.
        </p>
        <p>
            Codabox reçoit le premier fichier CODA dès que la banque a validé et activé le mandat, et qu'une première
            opération a eu lieu sur le compte. Nous vérifions ensuite la numérotation, la version et le solde, créons un
            fichier PDF qui reprend les informations du CODA, et vous fournissons des fichiers CODA quotidiens dans un
            format et une structure compatible avec votre programme comptable.
        </p>
        <h3>Tarifs</h3>

        <h4 class='cb-service-title'>
            Frais administratifs
        </h4>

        <p>Mandats : 24 € par mandat</p>

        <p v-if='!isDirectCustomer'>
            Service optionnel - Livraison des fichiers CODA à votre client par SFTP : 100 € par serveur SFTP (<a href='https://faq.codabox.com/fr/support/solutions/articles/75000087150-coda-%C3%A9galement-livr%C3%A9-%C3%A0-votre-client#link' target='_blank'>plus d’infos</a>)
        </p>

        <h4 class='cb-service-title'>
            Frais récurrents
        </h4>

        <table class='table' v-if='isDirectCustomer'>
            <tbody>
                <tr>
                    <td>> 2 comptes à vue</td>
                    <td>7,73 €/mois/compte</td>
                </tr>
                <tr>
                    <td>1 - 2 comptes à vue</td>
                    <td>9,62 €/mois/compte</td>
                </tr>
            </tbody>
        </table>
        <table class='table' v-else>
            <tbody>
                <tr>
                    <td>+ 500 comptes à vue</td>
                    <td>3,72 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 241 et 500 comptes à vue</td>
                    <td>4,65 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 121 et 240 comptes à vue</td>
                    <td>5,14 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 61 et 120 comptes à vue</td>
                    <td>6,45 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 3 et 60 comptes à vue</td>
                    <td>7,73 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 1 et 2 comptes à vue</td>
                    <td>9,62 €/mois/compte</td>
                </tr>
            </tbody>
        </table>

        <p v-if='isDirectCustomer'>
            Si vous gérez plusieurs entreprises sous un seul contrat Codabox, le nombre total de comptes à vue détermine le tarif des frais récurrents.
        </p>
        <p v-else>
            Pour déterminer le tarif correct, le nombre de services activés pour CARO et VOILA est également pris en compte.
            Vous trouverez plus de détails dans les Conditions Générales, via le lien ci-dessous.
        </p>
        <p class='note' v-if='isDirectCustomer'>
            <span class='fa fa-info-circle'></span>
            CODA reste actif jusqu'à ce que vous archiviez le service pour un certain compte via MyCodabox, ou que le mandat soit arrêté.
        </p>
        <p class='note' v-else>
            <span class='fa fa-info-circle'></span>
            Codabox ne peut pas déterminer quand le service <span v-if='isDirectCustomer'>CODA ou CARO</span><span v-else>CODA, CARO ou VOILA</span> n'est plus nécessaire pour un certain client, une carte de crédit ou un compte bancaire, par exemple lorsqu'il n'y a plus de transactions sur un compte ou le compte est clôturé. Le service reste actif (et fait partie du calcul des frais récurrents) jusqu'à ce que vous le
            <a href='https://faq.codabox.com/fr/support/solutions/articles/75000087558-comment-d%C3%A9sactiver-un-service-coda-soda-voila-caro-' target='_blank'>désactiviez vous-même via MyCodaBox</a>.
        </p>

        <h3>Conditions Générales</h3>
        <p v-if='isDirectCustomer'>
            <a href='/static/pdfs/20250101 Contrat CodaBox Business CODA-SODA-CARO_FR.pdf' target='_blank'>Contrat Codabox Business</a>
        </p>
        <p v-else>
            <a href='/static/pdfs/MyCodabox.com 202501 CB-Fidu FR-Annex CODA (B1-C1-D).pdf' target='_blank'>Conditions générales du service</a>
        </p>
    </div>
</template>

<style lang='scss' scoped>
    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }

    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
    p.note {
        font-style: italic;
    }
    p.note .fa-info-circle {
        font-size: 16px;
    }
    p, table {
        margin: 10px 0;
    }
    table {
        table-layout: fixed;
    }
    tbody {
        color: $primary-color-dark;
    }
</style>

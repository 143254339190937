<script>
import { gql } from '@apollo/client/core';
import Popup from '@/clientcomponents/Popup.vue';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import notify from '@/notify.js';

export default {
    props: {
        id: String,
        small: Boolean,
        extraSmall: Boolean,
        disabled: Boolean,
        alterOnSuccess: Boolean,
        hasBoost: Boolean,
        accountant: Object,
    },
    components: {
        Popup,
        FriendlyButton,
    },
    data () {
        return {
            popupShown: false,
            useContactEmailForDelivery: false,
            deactivateVoilaMandate: {},
            invite: {},
            client: {},
            ibanId: 0,
            success: false,
        };
    },
    created () {
        this.loadOrderDatas();
    },
    emits: ['success', 'done'],
    methods: {
        addIban (value) {
            this.ibanId += 1;
            this.invite.ibans.push({
                iban: value || '',
                id: this.ibanId,
            });
        },
        async orderVoila () {
            try {
                const { data } = await this.$apollo.mutate({
                    mutation: gql`mutation ($boost: Boolean!, $clientId: String!, $deliveryEmail: String, $ibans: [String]!) {
                        inviteClientToVoila(input: {
                            boost: $boost,
                            clientId: $clientId,
                            deliveryEmail: $deliveryEmail,
                            ibans: $ibans
                        }) {
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        boost: this.invite.useBoost,
                        clientId: this.invite.clientId,
                        deliveryEmail: this.invite.useContactEmailForDelivery
                            ? null
: this.invite.deliveryEmail || null,
                        ibans: this.invite.ibans.map(iban => iban.iban),
                    },
                });
                const res = data.inviteClientToVoila;
                if (res.errors) {
                    for (const err of res.errors) {
                        if (err.code === 'alreadyRegistered') {
                            notify.error(this.$t('err-voila-already-registered'));
                            this.closePopup();
                            this.$emit('done');
                            return;
                        }
                    }
                    notify.error(this.$t('err-unknown'));
                } else {
                    this.$emit('success');
                    this.success = true;
                }
            } catch (err) {
                notify.error(this.$t('err-unknown'));
                return;
            }

            this.closePopup();
            this.$emit('done');
        },
        async loadOrderDatas () {
            const { data } = await this.$apollo.query({
                query: gql`query OrderVoilaPopupData($clientId: String, $accountantId: String!) {
                        fiduciaryClient(clientId:$clientId) {
                            contactEmail
                        }
                        codaMandates(clientId:$clientId) {
                            state
                            bankAccounts {
                                state
                                iban
                            }
                        }
                        voilaBoostActivationByAccountant(accountantId:$accountantId) {
                            id
                        }
                    }`,
                variables: {
                    clientId: this.id,
                    accountantId: this.accountant.id,
                },
            });

            this.client = {
                ...data.fiduciaryClient,
                codaMandates: data.codaMandates,
                boost: data.voilaBoostActivationByAccountant,
            };

            this.invite = {
                useBoost: this.hasBoost,
                useContactEmailForDelivery: true,
                clientId: this.id,
                contactEmail: this.client.contactEmail,
                deliveryEmail: '',
                ibans: [],
            };
            const nonCancelledMandates = this.client.codaMandates.filter(
                mandate => mandate.state !== 'archived',
            );
            for (const iban of nonCancelledMandates.reduce((accounts, mandate) => {
                return accounts.concat(mandate.bankAccounts.filter(ba => ba.state === 'active'));
            }, []).map(ba => ba.iban)) {
                this.addIban(iban);
            }
        },
        openPopup () {
            this.popupShown = true;
        },
        closePopup () {
            this.popupShown = false;
        },
    },
};
</script>

<template>
    <div>
        <FriendlyButton
            class='voila-order-button'
            :label='btn-action-voila-done'
            :extra-small='extraSmall'
            symbol='check'
            :small='small'
            disabled
            no-margin
            square
            v-if='alterOnSuccess && success'
        />
        <FriendlyButton
            class='voila-order-button'
            :label='hasBoost ? "btn-order-voila-boost" : "btn-order-voila"'
            :action='openPopup'
            :extra-small='extraSmall'
            :small='small'
            :disabled='disabled'
            no-margin
            square
            v-else
            data-dd-action-name='btn-order-voila'
        />
        <Popup :show='popupShown' :close='closePopup' small>
            <template #header v-if='hasBoost'>
                {{ $t('ttl-order-voila-boost') }}
            </template>
            <template #header v-else>
                {{ $t('ttl-order-voila') }}
            </template>

            <div v-if='invite'>
                <template v-if='hasBoost'>
                    <p>
                        {{ $t('p-voila-order-boost-1') }}
                    </p>
                    <p>
                        <u>{{ $t('p-voila-order-boost-2') }}</u>: {{ $t('p-voila-order-boost-3') }}
                    </p>
                    <p>
                        <strong>
                            {{ $t('p-voila-order-boost-4') }}:
                        </strong>
                    </p>
                    <ul>
                        <li>
                            {{ $t('p-voila-order-boost-5') }}
                        </li>
                        <li>
                            {{ $t('p-voila-order-boost-6') }}
                        </li>
                    </ul>
                </template>
                <template v-else>
                    <p>
                        {{ $t('p-voila-order-1', { email: invite.contactEmail}) }}
                    </p>
                    <p>
                        {{ $t('p-voila-order-2') }}
                    </p>
                </template>
            </div>

            <template #buttons>
                <FriendlyButton
                    label='btn-cancel-voila-order'
                    class='voila-order-popup-close-button'
                    :action='closePopup'
                    symbol='times'
                    small
                    square
                    secondary
                />
                <FriendlyButton
                    :label='hasBoost ? "btn-confirm-voila-boost-order" : "btn-confirm-voila-order"'
                    :action='orderVoila'
                    small
                    symbol='check'
                    square
                    class='voila-order-popup-submit-button'
                />
            </template>
        </Popup>
    </div>
</template>

<script>
export default {
    name: 'TranslatedComponent',
};
</script>
<template>
    <slot name='en' v-if='$i18n.locale === "en_US"'></slot>
    <slot name='nl' v-if='$i18n.locale === "nl_BE"'></slot>
    <slot name='fr' v-if='$i18n.locale === "fr_FR"'></slot>
</template>

<script>
    export default {
        name: 'CcsTermsEnglishView',
        props: {
            isDirectCustomer: Boolean,
        },
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Service Description
        </h3>

        <p>
            Thanks to the CARO service, you can receive professional credit card statements directly and digitally, if the account holder and the cardholder have given their consent.
            This way, you always have a complete overview, and you can easily and correctly reconcile the individual transactions.
            You always receive all relevant credit card statements on time and you no longer have to enter them manually.
        </p>

        <p>
            The CARO files are delivered on a monthly basis, following the booking period of the credit card issuer, if at least 1 payment was made with the credit card during that period.
        </p>

        <p>
            The CARO files are given a logical name and are available in a PDF file and in a structured XML format.
        </p>

        <p>
            CARO is available for Mastercard and VISA credit cards issued by the following banks.
            The number of supported banks is gradually being expanded:
        </p>

        <p><a href='https://www.codabox.com/nl/caro/' target='_blank'>NL: https://www.codabox.com/nl/caro/ </a></p>
        <p><a href='https://www.codabox.com/fr/caro/' target='_blank'>FR: https://www.codabox.com/fr/caro/ </a></p>

        <p>
            Via MyCodabox you can order CARO and track the status of your applications.
            To activate CARO, there must be at least one active CODA mandate for the company.
        </p>

        <h3>Pricing</h3>

        <h4 class='cb-service-title'>
            Administrative costs
        </h4>

        <p>
            24 € per client reference (*) for which CARO has been activated
        </p>

        <h4 class='cb-service-title'>
            Recurrent costs
        </h4>

        <table class='table' v-if='isDirectCustomer'>
            <tbody>
                <tr>
                    <td>> 2 active CARO client references</td>
                    <td>7,73 €/month/client reference</td>
                </tr>
                <tr>
                    <td>1 - 2 active CARO client references</td>
                    <td>9,62 €/month/client reference</td>
                </tr>
            </tbody>
        </table>
        <p v-if='isDirectCustomer'>
            If you manage multiple companies under one Codabox contract, the total number of CARO client references determines the pricing for the recurring costs.
        </p>
        <p class='note' v-if='isDirectCustomer'>
            <span class='fa fa-info-circle'></span>
            CARO remains active until you archive the service via MyCodabox, or the mandate is discontinued.
        </p>
        <table class='table' v-if='!isDirectCustomer'>
            <tbody>
                <tr>
                    <td>+ 500 client references</td>
                    <td>3,72 €/month/client reference</td>
                </tr>
                <tr>
                    <td>Between 241 and 500 client references</td>
                    <td>4,65 €/month/client reference</td>
                </tr>
                <tr>
                    <td>Between 121 and 240 client references</td>
                    <td>5,14 €/month/client reference</td>
                </tr>
                <tr>
                    <td>Between 61 and 120 client references</td>
                    <td>6,45 €/month/client reference</td>
                </tr>
                <tr>
                    <td>Between 3 and 60 client references</td>
                    <td>7,73 €/month/client reference</td>
                </tr>
                <tr>
                    <td>For 1 and 2 client references</td>
                    <td>9,62 €/month/client reference</td>
                </tr>
            </tbody>
        </table>

        <p v-if='!isDirectCustomer'>
            To determine the correct rate, the number of activated CODA-services and VOILA-services is also taken into account.
            More details can be found in the General Terms and Conditions, via the links below.
        </p>

        <p class='note' v-if='!isDirectCustomer'>
            <span class='fa fa-info-circle'></span>
            Codabox cannot determine when the CODA, CARO or VOILA service is no longer needed for a particular client,
            credit card or bank account, for example, when there are no more transactions on an account, or the account is closed.
            The service remains active (and continues to be included in the calculation of recurrent costs) until you
            <a href='https://faq.codabox.com/en/support/solutions/articles/75000087558-how-to-deactivate-a-service-coda-soda-voila-caro-' target='_blank'>deactivate it yourself via MyCodabox</a>.
        </p>

        <p>
            (*) identification of the credit card statement: some bank products will allow multiple cards on 1 credit card statement (i.e. client reference).
        </p>

        <h3>General Terms and Conditions</h3>
        <p v-if='isDirectCustomer'>
            <a href='/static/pdfs/20250101 Overeenkomst CodaBox Business CODA-SODA-CARO_NL.pdf' target='_blank'>Overeenkomst Codabox Business (available in Dutch)</a><br>
            <a href='/static/pdfs/20250101 Contrat CodaBox Business CODA-SODA-CARO_FR.pdf' target='_blank'>Contrat Codabox Business (available in French)</a>
        </p>
        <p v-else>
            Terms and conditions of the service:
            <a href='/static/pdfs/MyCodabox.com 202501 CB-Fidu FR-Annex CARO (B4-C4-D).pdf' target='_blank'>FR </a>
            <a href='/static/pdfs/MyCodabox.com 202501 CB-Fidu NL-Annex CARO (B4-C4-D).pdf' target='_blank'>NL</a>
        </p>
    </div>
</template>

<style lang='scss' scoped>
    h3.cb-service-main-title {
        margin-top: 10px;
    }
    h4.cb-service-title {
        text-decoration: underline;
    }
    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
    p.note {
        font-style: italic;
    }
    p.note .fa-info-circle {
        font-size: 16px;
    }
    p, table {
        margin: 10px 0;
    }
    table {
        table-layout: fixed;
    }
    tbody {
        color: $primary-color-dark;
    }
</style>

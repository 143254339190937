<script setup>

import { useMutation } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import notify from '@/notify';
import { useI18n } from 'vue-i18n';
import Loader from '@/loader';

const { t } = useI18n();

const props = defineProps({
    mandate: {
        type: Object,
    },
});

const emit = defineEmits(['reinvited']);

function reinvited () {
    emit('reinvited');
}

async function doReinviteTwikey (values) {
    Loader.start();
    const { mutate: reinviteTwikey } = await useMutation(gql`
        mutation ReinviteTwikey($input: ReinviteTwikeyInput!) {
            reinviteTwikey(input: $input) {
                errors {
                    code
                    detail
                    source {
                        pointer
                    }
                }
            }
        }
    `);

    try {
        const res = (await reinviteTwikey({ input: { codaMandateId: props.mandate.id, clientId: props.mandate.clientId } })).data.reinviteTwikey;

        if (res.errors) {
            notify.error(t('err-unknown-retry-later'));
            return;
        }
        notify.success(t('lbl-success-reinvite-twikey'));
        reinvited();
    } catch (error) {
        notify.error(t('err-unknown-retry-later'));
    }
    Loader.stop();
}

</script>

<template>
    <div class='sign-button'>
        <button
            class='btn btn-sm btn-default px-2 ml-3'
            @click='doReinviteTwikey'
        >
            {{ $t('btn-reinvite-twikey') }}
            <i class='fa fa-rotate-right ml-1'></i>
        </button>
    </div>
</template>

<style lang='postcss' scoped>
.sign-button {
    @apply rounded-md text-blue-500 text-sm;
    display: flex;
    height: 30px;
    align-items: center;
    text-decoration: none;
}

.sign-button img {
    display: inline-block;
    max-height: 1.2em;
    margin-left: 6px;
    position: relative;
    top: -1px;
}
</style>

<script>
export default {
    name: 'DropdownItemComponent',
    props: {
        id: String,
        default: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        name: String,
    },
    mounted () {
        if (!this.disabled && (this.default || this.$parent.selected === this.id)) {
            this.activate();
        }
    },
    emits: ['click'],
    methods: {
        click () {
            this.$emit('click', this.id);
            this.activate();
        },
        activate (e) {
            if (!this.disabled) {
                this.$parent.change(this.id);
            }
        },
    },
};
</script>

<template>
    <div
        @click='click'
        :class='disabled ? "dropdown-v2-item-disabled text-grey-300" : "dropdown-v2-item"'
    >
        <slot></slot>
    </div>
</template>

<script>
import { gql } from '@apollo/client/core';
import Loader from '@/loader';
import validate from '@/validate';
import notify from '@/notify';
import FormInput from '@/components/FormInput';
import ContentBox from '@/components/ContentBox';
import OrderCaro from '@/fiduciary/components/OrderCaro.vue';
import InsightInfo from '@/components/InsightInfo.vue';
import { mapState } from 'vuex';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import { dateFormat } from '@/filters';
import { Form } from 'vee-validate';
import Popup from '@/clientcomponents/Popup.vue';
import FormTextarea from '@/components/FormTextarea.vue';
import FormSelect from '@/components/FormSelect.vue';
import { t } from '@/i18n';
import InfoBanner from '@/components/InfoBanner';
import InfoBannerItem from '@/components/InfoBannerItem';
import DigitalSignatureButton from '@/components/DigitalSignatureButton.vue';
import Dropdown from '@/components/DropdownV2.vue';
import Tooltip from '@/components/Tooltip.vue';
import CodaboxExternalLink from '@/components/CodaboxExternalLink.vue';

const EDIT = 'edit';
const NEW = 'new';

export default {
    name: 'ClientCaro',
    props: {
        clientV2: {
            type: Object,
            required: true,
        },
        accountant: {
            type: Object,
            required: true,
        },
    },
    components: {
        CodaboxExternalLink,
        Dropdown,
        DigitalSignatureButton,
        FormSelect,
        FormTextarea,
        InsightInfo,
        OrderCaro,
        Tooltip,
        FormInput,
        ContentBox,
        FriendlyButton,
        // eslint-disable-next-line vue/no-reserved-component-names
        Form,
        Popup,
        InfoBanner,
        InfoBannerItem,
    },
    data () {
        return {
            saving: false,
            loading: false,
            showActivationModal: false,
            showReconnectModal: false,
            showReactivateModal: false,
            activation: {},
            activationType: null,
            reconnect: {},
            reactivate: {},
            registrations: [],
            codaMandates: [],
            downloadDate: {
                year: null,
                month: null,
            },
            displayDownloadPopup: false,
            showDeactivationModal: false,
            registrationToDeactivate: null,
            registrationToReactivate: null,
            deactivationPayload: {
                reason: '',
                freeNote: '',
            },

            // otherwise it's not available in template
            NEW,
            EDIT,
        };
    },
    computed: {
        hasSubscribedCcs () {
            return this.registrations && this.registrations.length > 0;
        },
        hasNoRegistrationsWithLastReceivedAt () {
            return this.registrations.every((r) => r.lastReceivedAt === null);
        },
        isClientEligible () {
            return this.codaMandates.findIndex(mandate => {
                return mandate.bank.isCaroSupported;
            }) >= 0;
        },
        isClientOrderable () {
            return this.codaMandates.findIndex(mandate => {
                return mandate.state === 'active' && mandate.bank.isCaroSupported;
            }) >= 0;
        },
        downloadYears () {
            const now = new Date();
            const years = [];

            for (let index = 0; index < 3; index++) {
                years.push((now.getFullYear() - index).toString());
            }

            return years;
        },
        downloadMonths () {
            const months = [];

            for (let index = 1; index < 13; index++) {
                months.push(index.toString().padStart(2, '0'));
            }

            return months;
        },
        hasDeactivatedByClientProblem () {
            return this.currentClientProblems.some(p => p.type === 'caroDeactivatedByClient');
        },
        ...mapState(['currentClientProblems']),
    },
    async beforeMount () {
        if (this.accountant.hasCodaOnlyContract) {
            // user can't access this page for this environment
            notify.error(t('err-restricted-access'));
            this.$router.push({ name: 'index' });
        } else {
            this.loading = true;
            await this.fetchCodaMandates();  // get coda mandates to check if client is eligible
            if (this.isClientEligible) {
                await this.loadRegistrations();
            }
            this.loading = false;
        }
    },
    methods: {
        dateFormat,
        async loadRegistrations (params = { loading: true }) {
            Loader.start();
            this.loading = true;
            if (params.loading) {
                this.loading = true;
            }
            await this.queryCaroRegistrations();
            Loader.stop();
            this.loading = false;
        },
        sortRegistrationsByStatus (a, b) {
          // inverse list, so that statusses that are not in this list will end up at the bottom, not the top
            const regStats = ['INACTIVE', 'ACTIVE', 'PROBLEM', 'DEACTIVATED_BY_CLIENT', 'INVITATION_SENT'];
            const regA = regStats.indexOf(a.status);
            const regB = regStats.indexOf(b.status);

            if (regA === regB) {
                // in case of INACTIVE, order by activationStatus, so that the active ones are at the top
                if (a.status === 'INACTIVE') {
                    const actStats = ['LIQUIDATED', 'INACTIVE', 'ACTIVE'];
                    const actA = actStats.indexOf(a.activation?.status);
                    const actB = actStats.indexOf(b.activation?.status);
                    if (actA !== actB) {
                        return actB - actA;
                    }
                }
                // if statussess are equal. sort by clientReference
                return  a.clientReference.localeCompare(b.clientReference);
            }
            return regB - regA;
        },
        async queryCaroRegistrations () {
            try {
                const { data } = await this.$apollo.query({
                    query: gql`query CaroRegistrations($clientId: String) {
                            caroRegistrations(clientId:$clientId) {
                                id
                                clientReference
                                status
                                lastReceivedAt
                                iban
                                invitation {
                                    id
                                    clientEmail
                                    bankBicCode
                                }
                                activation {
                                    status
                                }
                            }

                        }`,
                    variables: {
                        clientId: this.clientV2.id,
                    },
                });
                this.registrations = data.caroRegistrations.sort(this.sortRegistrationsByStatus);
            } catch (e) {
                notify.error(this.$t('err-unknown'));
            }
        },
        async fetchCodaMandates () {
            try {
                const { data } = await this.$apollo.query({
                    query: gql`query getCodaMandate($clientId: String) {
                            codaMandates(clientId:$clientId, excludeMandatesWithoutBankAccounts:true) {
                                state
                                bank {
                                    isCaroSupported
                                }
                            }
                        }`,
                    variables: {
                        clientId: this.clientV2.id,
                    },
                });
                this.codaMandates = data.codaMandates;
            } catch {
                notify.error(this.$t('err-unknown'));
            }
        },
        async sendCaroReconnectEmail (values) {
            try {
                this.saving = true;
                Loader.start();
                const { data } = await this.$apollo.query({
                    query: gql`mutation sendCaroReconnectEmail($input: CaroReconnectEmailInput!) {
                                sendCaroReconnectEmail(input: $input) {
                                    errors {
                                        code,
                                        detail,
                                        source {
                                            pointer
                                        }
                                    }
                                }
                            }`,
                    variables: {
                        input: {
                            ...this.reconnect,
                            ...{
                                clientEmail: values.clientEmail,
                            },
                        },
                    },
                });
                if (data.sendCaroReconnectEmail.errors) {
                    notify.error(this.$t('err-retry'));
                    return;
                } else {
                    notify.success(this.$t('suc-ccs-reconnected'));
                }
            } catch {
                notify.error(this.$t('err-unknown'));
            } finally {
                this.saving = false;
                Loader.stop();
            }
            this.closeReconnectModal();
        },
        async reactivateCaro (values) {
            try {
                this.saving = true;
                Loader.start();
                const { data } = await this.$apollo.query({
                    query: gql`mutation reactivateCaro($input: CaroReactivateInput!) {
                                reactivateCaro(input: $input) {
                                    errors {
                                        code,
                                        detail,
                                        source {
                                            pointer
                                        }
                                    }
                                }
                            }`,
                    variables: {
                        input: {
                            ...this.reactivate,
                        },
                    },
                });
                if (data.reactivateCaro.errors) {
                    notify.error(this.$t('err-retry'));
                    return;
                } else {
                    notify.success(this.$t('suc-ccs-reactivated'));
                    await this.loadRegistrations({ loading: false });
                }
            } catch {
                notify.error(this.$t('err-unknown'));
            } finally {
                this.saving = false;
                Loader.stop();
            }
            this.closeReactivateModal();
        },
        openActivationModal (registration = null) {
            if (registration) {
                this.activationType = EDIT;
                this.activation = {
                    bankBicCode: registration.invitation.bankBicCode,
                    clientReference: registration.clientReference,
                    clientEmail: registration.invitation.clientEmail,
                    iban: registration.iban === undefined || registration.iban === null ? '' : registration.iban,
                };
            } else {
                this.activationType = NEW;
                this.activation = {
                    clientEmail: this.clientV2.contactEmail,
                };
            }

            this.showActivationModal = true;
        },

        closeActivationModal () {
            this.showActivationModal = false;
        },
        openDownloadModal () {
            this.displayDownloadPopup = true;
        },
        closeDownloadModal () {
            this.displayDownloadPopup = false;
        },
        openDeactivationModal (registration) {
            this.registrationToDeactivate = registration;
            this.showDeactivationModal = true;
        },
        closeDeactivationModal () {
            this.registrationToDeactivate = null;
            this.showDeactivationModal = false;
        },
        openReconnectModal (registration) {
            this.reconnect = {
                clientId: this.clientV2.id,
                registrationId: registration.id,
                clientEmail: this.clientV2.contactEmail,
                language: this.clientV2.language,
                clientLegalName: this.clientV2.representativeName,
                accountantLegalName: this.accountant.enterpriseName,
            };
            this.showReconnectModal = true;
        },
        closeReconnectModal () {
            this.showReconnectModal = false;
        },
        openReactivateModal (registration) {
            this.registrationToReactivate = registration;
            this.reactivate = {
                clientId: this.clientV2.id,
                registrationId: registration.id,
            };
            this.showReactivateModal = true;
        },
        closeReactivateModal () {
            this.reactivate = null;
            this.registrationToReactivate = null;
            this.showReactivateModal = false;
        },

        async doClickDownloadFiles (values) {
            Loader.start();
            try {
                const billingMonth = `${values.downloadDateYear}-${values.downloadDateMonth}`;
                const queryRes = await this.queryDownloadFilesUrl(billingMonth);

                if (!queryRes.errors) {
                    window.open(queryRes.downloadUrl);
                } else {
                    validate.notifyGQLValidationErrors(queryRes.errors, {
                        NO_CREDIT_CARD_STATEMENTS_FOUND: 'err-ccs-no-deliveries-found',
                    });
                }
            } catch (e) {
                notify.error(this.$t('err-unknown'));
            }
            this.closeDownloadModal();
            Loader.stop();
        },
        async queryDownloadFilesUrl (billingMonth) {
            const { data } = await this.$apollo.query({
                query: gql`query CaroFilesDownloadUrl($clientId: String, $billingMonth: String) {
                        caroFilesDownloadUrl(clientId:$clientId, billingMonth:$billingMonth) {
                            downloadUrl
                            errors { code }
                        }
                    }`,
                variables: {
                    clientId: this.clientV2.id,
                    billingMonth,
                },
            });
            return data.caroFilesDownloadUrl;
        },
        async doClickSuspend (values) {
            Loader.start();
            await this.suspendCaro(values);
            Loader.stop();
        },
        async suspendCaro (values) {
            try {
                await this.$apollo.mutate({
                    mutation: gql`mutation SuspendCaro($input: CaroSuspensionInput) {
                        suspendCaro(input: $input) {
                            errors {
                                code,
                                detail
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            clientId: this.clientV2.id,
                            clientReference: this.registrationToDeactivate.clientReference,
                            reason: values.reason,
                            freeNote: values.freeNote,
                        },
                    },
                });
                notify.success(this.$t('suc-ccs-deactivation'));
                this.closeDeactivationModal();
                await this.loadRegistrations({ loading: false });
            } catch (e) {
                notify.error(this.$t('err-unknown'));
            }
        },
        stateLabel (state) {
            return {
                ACTIVE: 'ccs-status-lbl-active',
                INACTIVE: 'ccs-status-lbl-inactive',
                INVITATION_SENT: 'ccs-status-lbl-invitation-sent',
                DEACTIVATED_BY_CLIENT: 'ccs-status-lbl-deactivated-by-client',
                PROBLEM: 'ccs-status-lbl-problem',
            }[state];
        },
        stateInfoLabel (registration) {
            return {
                ACTIVE: 'ccs-status-info-active',
                INACTIVE: this.activationIsActive(registration) ? 'ccs-status-info-inactive-can-reactivate' : 'ccs-status-info-inactive',
                INVITATION_SENT: 'ccs-status-info-invitation-sent',
                DEACTIVATED_BY_CLIENT: 'ccs-status-info-deactivated-by-client',
                PROBLEM: 'ccs-status-info-problem',
            }[registration.status];
        },
        markLastReceivedAt (lastReceivedAt) {
            const lastReceivedAtDate = new Date(lastReceivedAt);
            const compareDate = new Date();
            compareDate.setMonth(compareDate.getMonth() - 6);

            return lastReceivedAtDate < compareDate;
        },
        isKbcCbc (registration) {
            return ['KREDBEBB', 'CREGBEBB'].includes(registration.invitation.bankBicCode);
        },
        needsSignature (registration) {
            return registration.status === 'INVITATION_SENT' && !this.isKbcCbc(registration);
        },
        signatureUrl (registration) {
            if (registration?.invitation?.id) {
                return `${this.$store.state.env.caroOnboardingBaseUrl}/activation/${registration.invitation.id}`;
            }
            return '';
        },
        needsFaqLink (registration) {
            return registration.status === 'INVITATION_SENT' && this.isKbcCbc(registration);
        },
        faqLink (registration) {
            return this.$t('ccs-faq-kbc-activation-url');
        },
        canEditRegistration (registration) {
            const statuses = ['INVITATION_SENT', 'PROBLEM'];
            return statuses.includes(registration.status);
        },
        canDeactivate (registration) {
            return registration.status !== 'INACTIVE';
        },
        activationIsActive (registration) {
            return registration?.activation?.status === 'ACTIVE';
        },
        canReactivate (registration) {
            return registration.status === 'INACTIVE' && this.activationIsActive(registration);
        },
        showDeactivatedByClientProblem (registration) {
            return this.hasDeactivatedByClientProblem && registration.status === 'DEACTIVATED_BY_CLIENT';
        },
        isDeactivatedByClient (registration) {
            return registration.status === 'DEACTIVATED_BY_CLIENT';
        },
    },
};
</script>
<template>
    <template v-if='!loading'>
        <content-box v-if='hasSubscribedCcs' :title='$t("h-caro-orders")'>
            <template #actions>
                <FriendlyButton
                    label='btn-ccs-download-files'
                    :action='() => openDownloadModal()'
                    :disabled='hasNoRegistrationsWithLastReceivedAt'
                    square
                    extra-small
                    no-margin
                    secondary
                    class='mr-2'
                />
                <FriendlyButton
                    label='btn-ccs-new-activation'
                    :action='() => openActivationModal()'
                    :disabled='!isClientOrderable'
                    square
                    extra-small
                    no-margin
                />
            </template>
            <div :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table'>
                    <colgroup>
                        <col class='cb-col-fit'>
                        <col class='cb-col-fit'>
                        <col class='cb-col-fit'>
                        <col class='cb-col-fit'>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th class='text-nowrap pr-6'>
                                {{ $t('th-client-reference') }}
                            </th>
                            <th class='text-nowrap pr-6'>
                                {{ $t('th-ccs-status') }}
                            </th>
                            <th class='text-nowrap pr-6'>
                                {{ $t('th-ccs-cardholder') }}
                            </th>
                            <th class='text-nowrap pr-6'>
                                <span class='cb-state-label'>
                                    {{ $t('th-ccs-last-file-received-at') }}
                                </span>
                                <tooltip class='cb-legend' left>
                                    <template #trigger>
                                        <i class='fa fa-info-circle'></i>
                                    </template>
                                    <template #content>
                                        <div class='whitespace-normal'>
                                            {{ $t('ccs-last-file-received-at-info') }}
                                        </div>
                                    </template>
                                </tooltip>
                            </th>
                            <th class='text-nowrap'>
                                {{ $t('th-actions') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for='(registration, index) in registrations'
                            :key='index'
                            :id='"registration-" + registration.id'
                            :class='{"problem-row": showDeactivatedByClientProblem(registration)}'
                        >
                            <td class='pr-6'>
                                {{ registration.clientReference }}
                            </td>
                            <td class='pr-6'>
                                <InsightInfo :has-problem='showDeactivatedByClientProblem(registration)'>
                                    <div class='flex flex-col items-start'>
                                        <div class='whitespace-nowrap'>
                                            <span class='cb-state-label'>
                                                {{ $t(stateLabel(registration.status)) }}
                                            </span>
                                            <tooltip class='cb-legend' right>
                                                <template #trigger>
                                                    <i class='fa fa-info-circle'></i>
                                                </template>
                                                <template #content>
                                                    <!-- eslint-disable-next-line vue/no-v-html -->
                                                    <div class='whitespace-normal' v-html='$t(stateInfoLabel(registration))'></div>
                                                </template>
                                            </tooltip>
                                        </div>
                                        <div v-if='needsSignature(registration)' class='w-fit mt-1'>
                                            <DigitalSignatureButton
                                                label-link='lbl-ccs-sign-link-start'
                                                label-button='lbl-ccs-sign-link-copy'
                                                :url='signatureUrl(registration)'
                                                open-link
                                            />
                                        </div>
                                        <CodaboxExternalLink
                                            class='pt-3'
                                            v-if='needsFaqLink(registration)'
                                            label='lbl-ccs-sign-faq'
                                            :url='faqLink(registration)'
                                        />
                                        <FriendlyButton
                                            label='btn-ccs-reactivate'
                                            square
                                            :action='() => openReactivateModal(registration)'
                                            v-if='canReactivate(registration)'
                                            extra-small
                                            micro
                                            no-margin
                                            class='mt-1'
                                        />
                                        <FriendlyButton
                                            label='btn-ccs-reconnect'
                                            square
                                            :action='() => openReconnectModal(registration)'
                                            v-if='isDeactivatedByClient(registration)'
                                            extra-small
                                            micro
                                            no-margin
                                            class='mt-1'
                                        />
                                    </div>
                                </InsightInfo>
                            </td>
                            <td class='pr-6 max-w-xs'>
                                <div>
                                    {{ registration.invitation ? registration.invitation.clientEmail : '' }}
                                </div>
                            </td>
                            <td class='pr-6'>
                                <span v-if='registration.lastReceivedAt' :class='{"text-orange-300":markLastReceivedAt(registration.lastReceivedAt)}'>
                                    {{ dateFormat(registration.lastReceivedAt) }}
                                </span>
                                <span v-else>-</span>
                            </td>
                            <td class='button-bar align-middle'>
                                <div class='head__actions w-fit'>
                                    <Dropdown
                                        direction='right'
                                        :name='$t("lbl-coda-action")'
                                        button-small
                                        dark
                                        dark-menu
                                        icon='sort'
                                        menu-extra-classes='overflow-y-visible'
                                        :disabled='!(canEditRegistration(registration) || canDeactivate(registration))'
                                    >
                                        <FriendlyButton
                                            label='btn-ccs-edit-activation'
                                            id='edit-caro-order-button'
                                            square
                                            :action='() => openActivationModal(registration)'
                                            v-if='canEditRegistration(registration)'
                                            extra-small
                                            no-margin
                                            fullwidth
                                            class='mb-2'
                                        />
                                        <FriendlyButton
                                            label='btn-ccs-deactivation'
                                            square
                                            danger
                                            :action='() => openDeactivationModal(registration)'
                                            v-if='canDeactivate(registration)'
                                            extra-small
                                            fullwidth
                                            no-margin
                                        />
                                    </Dropdown>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </content-box>
        <content-box v-else>
            <div class='flex items-start mb-3'>
                <span
                    class='py-1 px-3 rounded-full inline-block whitespace-nowrap bg-opacity-20 bg-grey-300'
                >
                    {{ $t('caro-not-ordered') }}
                </span>
                <FriendlyButton
                    label='btn-add-activation'
                    :action='() => openActivationModal()'
                    :disabled='!isClientOrderable'
                    square
                    extra-small
                    no-margin
                    class='ml-auto'
                    id='order-caro-button'
                    data-dd-action-name='btn-order-caro'
                />
            </div>
            <InfoBanner>
                <InfoBannerItem v-if='!isClientOrderable'>
                    <div>
                        <span>
                            {{ $t('info-ccs-not-eligible') }} <br>
                        </span>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-if='!accountant.isDirectCustomer' v-html='$t("p-caro-supported-banks-info")'></span>
                        <span v-else v-html='$t("p-caro-supported-banks-info-dc")'></span>
                    </div>
                </InfoBannerItem>
            </InfoBanner>
        </content-box>

        <OrderCaro
            :client-v2='clientV2'
            :accountant='accountant'
            :activation='activation'
            :visible='showActivationModal'
            :is-new='activationType === NEW'
            @caro-invitation-sent='loadRegistrations'
            @order-caro-closed='closeActivationModal'
        />

        <Form @submit='doClickDownloadFiles' ref='downloadForm'>
            <Popup :show='displayDownloadPopup' :close='closeDownloadModal' id='download-files-modal'>
                <template #header>
                    {{ $t('ttl-css-download-files', { enterprise_name: clientV2.enterpriseName }) }}
                </template>

                <div>
                    <FormSelect
                        name='downloadDateYear'
                        :placeholder='$t("lbl-year")'
                        edit
                        rules='required'
                        narrow
                    >
                        <template #options>
                            <option
                                v-for='downloadYear in downloadYears'
                                :value='downloadYear'
                                :key='downloadYear'
                            >
                                {{ downloadYear }}
                            </option>
                        </template>
                    </FormSelect>
                </div>
                <div class='mt-6'>
                    <FormSelect
                        name='downloadDateMonth'
                        :placeholder='$t("lbl-month")'
                        edit
                        rules='required'
                        narrow
                    >
                        <template #options>
                            <option
                                v-for='downloadMonth in downloadMonths'
                                :value='downloadMonth'
                                :key='downloadMonth'
                            >
                                {{ downloadMonth }}
                            </option>
                        </template>
                    </FormSelect>
                </div>
                <template #buttons>
                    <FriendlyButton
                        label='btn-cancel'
                        :action='closeDownloadModal'
                        square
                        extra-small
                        no-margin
                        secondary
                        class='mr-2'
                    />
                    <FriendlyButton
                        label='btn-ccs-download-files'
                        :disabled='saving'
                        square
                        extra-small
                        no-margin
                        type='submit'
                    />
                </template>
            </Popup>
        </Form>

        <Form
            ref='deactivationForm'
            @submit='doClickSuspend'
            v-slot='{ values }'
        >
            <Popup :show='showDeactivationModal' :close='closeDeactivationModal' id='deactivation-modal'>
                <template #header>
                    {{ $t('ttl-css-deactivation', {client_reference: registrationToDeactivate ? registrationToDeactivate.clientReference : ''}) }}
                </template>

                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-html='$t("p-ccs-deactivation")'></p>

                <div class='deactivation-inputs mt-5'>
                    <FormSelect
                        v-model='deactivationPayload.reason'
                        name='reason'
                        :label='$t("lbl-css-deactivation-reason")'
                        :placeholder='$t("select-css-deactivation-reason")'
                        edit
                        rules='required'
                        narrow
                    >
                        <template #options>
                            <option value='CARD_STOPPED'>
                                {{ $t('lbl-css-reason-card-stopped') }}
                            </option>
                            <option value='CHANGED_BANK'>
                                {{ $t('lbl-css-reason-changed-bank') }}
                            </option>
                            <option value='TOO_EXPENSIVE'>
                                {{ $t('lbl-ccs-reason-too-expensive') }}
                            </option>
                            <option value='NOT_SATISFIED'>
                                {{ $t('lbl-css-reason-not-satisfied') }}
                            </option>
                            <option value='BUSINESS_STOPPED'>
                                {{ $t('lbl-css-reason-business-stopped') }}
                            </option>
                            <option value='CLIENT_NOT_PAYING'>
                                {{ $t('lbl-css-reason-client-not-paying') }}
                            </option>
                            <option value='NOT_DISCLOSED'>
                                {{ $t('lbl-css-reason-not-discloser') }}
                            </option>
                            <option value='CLIENT_MOVED_ACCOUNTANT'>
                                {{ $t('lbl-css-reason-client-moved-accountant') }}
                            </option>
                            <option value='OTHER'>
                                {{ $t('lbl-css-reason-other') }}
                            </option>
                        </template>
                    </FormSelect>
                    <FormTextarea
                        name='freeNote'
                        :placeholder='$t("lbl-css-reason-other")'
                        v-if='values.reason === "OTHER"'
                        edit
                        rules='required|max:255'
                        class='mt-3'
                    />
                </div>
                <template #buttons>
                    <FriendlyButton
                        label='btn-ccs-cancel-deactivation'
                        :action='closeDeactivationModal'
                        square
                        extra-small
                        no-margin
                        secondary
                        class='mr-2'
                    />
                    <FriendlyButton
                        label='btn-ccs-deactivation'
                        :disabled='saving'
                        square
                        extra-small
                        no-margin
                        type='submit'
                    />
                </template>
            </Popup>
        </Form>
        <Form
            ref='reconnectForm'
            @submit='sendCaroReconnectEmail'
        >
            <Popup :show='showReconnectModal' :close='closeReconnectModal' id='reconnect-modal'>
                <template #header>
                    {{ $t('ttl-ccs-reconnect') }}
                </template>
                <p>{{ $t('body-ccs-reconnect') }}</p>
                <div>
                    <FormInput
                        :value='reconnect.clientEmail'
                        name='clientEmail'
                        :label='$t("lbl-ccs-client-email")'
                        rules='required|min:3|max:255|email'
                        :placeholder='$t("lbl-email")'
                        edit
                        narrow
                        class='mt-2'
                    />
                </div>
                <template #buttons>
                    <FriendlyButton
                        label='btn-cancel'
                        :action='closeReconnectModal'
                        square
                        extra-small
                        no-margin
                        secondary
                        class='mr-2'
                    />
                    <FriendlyButton
                        :label='"btn-ccs-reconnect"'
                        :disabled='saving'
                        square
                        extra-small
                        no-margin
                        id='reconnect-caro-modal-button'
                        type='submit'
                    />
                </template>
            </Popup>
        </Form>
        <Form
            ref='reactivateForm'
            @submit='reactivateCaro'
        >
            <Popup :show='showReactivateModal' :close='closeReactivateModal' id='reactivate-modal'>
                <template #header>
                    {{ $t('ttl-ccs-reactivate', {client_reference: registrationToReactivate ? registrationToReactivate.clientReference: ''}) }}
                </template>
                <p>{{ $t('body-ccs-reactivate') }}</p>
                <template #buttons>
                    <FriendlyButton
                        label='btn-cancel'
                        :action='closeReactivateModal'
                        square
                        extra-small
                        no-margin
                        secondary
                        class='mr-2'
                    />
                    <FriendlyButton
                        :label='"btn-ccs-reactivate"'
                        :disabled='saving'
                        square
                        extra-small
                        no-margin
                        id='reactivate-caro-modal-button'
                        type='submit'
                    />
                </template>
            </Popup>
        </Form>
    </template>
</template>

<style lang='scss' scoped>
    .problem-row {
        background-color: #fbe1e1;
    }

    .client-subppage {
        min-height: calc(100vh - 450px);
    }

    .client-subppage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0 20px;

        h1, h2, h3, h4, h5, h6 {
            margin: 0;
        }
    }

    .client-page__settings {
        margin-top: 20px;
    }

    .client-subppage-subtitle {
        margin: 40px 0 20px 0;
    }

    .client-subppage-header__actions {
        @apply flex items-center ml-auto;
    }

    .btn-call-to-action {
        background-color: $primary-color;
        color: white;
    }
</style>

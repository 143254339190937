<script>
import Tooltip from '@/components/Tooltip.vue';

export default {
    name: 'StatePill',
    props: {
        label: String,
        legend: String,
        backgroundClass: String,
        inline: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Tooltip,
    },
};
</script>

<template>
    <div class='state-pill'>
        <div v-if='inline' class='flex items-center'>
            <span
                class='py-1 px-3 rounded-full inline-block whitespace-nowrap text-white bg-opacity-70'
                :class='backgroundClass'
            >
                {{ label }}
            </span>
            <p class='m-0 pl-3' v-if='legend'>
                {{ legend }}
            </p>
        </div>
        <tooltip big v-else>
            <template #trigger>
                <span
                    class='py-1 px-3 rounded-full inline-block whitespace-nowrap text-white bg-opacity-90'
                    :class='backgroundClass'
                >
                    {{ label }}
                </span>
            </template>
            <template #content>
                {{ legend }}
            </template>
        </tooltip>
    </div>
</template>

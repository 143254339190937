<script>
    import utils from '../utils.js';
    import Tooltip from '@/components/Tooltip.vue';

    export default {
        name: 'VerifiedEmail',
        props: {
            email: String,
            verificationState: String,
        },
        components: {
            Tooltip,
        },
        computed: {
            valid () {
                if (this.email) {
                    return utils.validateEmail(this.email);
                } else {
                    return false;
                }
            },
            verificationStateColor () {
                return {
                    new: '',
                    'verification-sent': 'in-progress-color',
                    'reminder-sent': 'in-progress-color',
                    'failed-expired': 'failed-color',
                    'failed-refused': 'failed-color',
                    'failed-challenge': 'failed-color',
                    'verified-pi-mandate': 'verified-color',
                    'verified-email': 'verified-color',
                    'verified-migration': 'verified-color',
                }[this.verificationState];
            },
            verificationStateLegend () {
                return this.$t({
                    new: 'lgnd-email-verification-state-none',
                    'verification-sent': 'lgnd-email-verification-state-in-progress',
                    'reminder-sent': 'lgnd-email-verification-state-in-progress',
                    'failed-expired': 'lgnd-email-verification-state-failed',
                    'failed-refused': 'lgnd-email-verification-state-failed',
                    'failed-challenge': 'lgnd-email-verification-state-failed',
                    'verified-pi-mandate': 'lgnd-email-verification-state-verified',
                    'verified-email': 'lgnd-email-verification-state-verified',
                    'verified-migration': 'lgnd-email-verification-state-verified',
                }[this.verificationState]);
            },
        },
    };
</script>

<template>
    <span>
        <template v-if='email'>
            <tooltip big>
                <template #trigger>
                    <span class='truncate mr-3' :class='verificationStateColor'>{{ email }}</span>
                    <i class='fa fa-info-circle text-grey-300'></i>
                </template>
                <template #content>
                    {{ verificationStateLegend }}
                </template>
            </tooltip>
        </template>
        <template v-else>
            {{ $t('lbl-missing-data') }}
        </template>
    </span>
</template>

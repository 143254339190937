<script>
    export default {
        name: 'ColumnComponent',
        props: {
            sorting: {
                type: Object,
                required: true,
            },
            skey: {
                type: String,
                required: true,
            },
        },
    };
</script>
<template>
    <col :class='{"cb-sorted": sorting.key === skey}'>
</template>

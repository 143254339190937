<script>
    export default {
        name: 'ClientEnterpriseNumber',
        props: {
            enterpriseNumber: String,
        },
        computed: {
            valid () {
                return !!this.enterpriseNumber;
            },
        },
    };
</script>

<template>
    <span>
        <span class='cb-state-label'>
            <template v-if='enterpriseNumber'> {{ enterpriseNumber }} </template>
            <template v-else> - </template>
        </span>
    </span>
</template>

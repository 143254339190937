<template>
    <li :class='liClasses' class='flex items-center'>
        <i :class='`fa fa-${icon} mr-3 text-base`'></i>
        <slot></slot>
    </li>
</template>

<script setup>
import { computed, inject, onMounted, onBeforeUnmount, ref } from 'vue';

defineProps({
    icon: {
        type: String,
        default: () => 'info-circle',
    },
});

const items = inject('items', ref([]));
const item = ref(null);

onMounted(() => {
    item.value = {};
    items.value.push(item.value);
});

onBeforeUnmount(() => {
    items.value = items.value.filter(i => i !== item.value);
});

const liClasses = computed(() => {
    const index = items.value.indexOf(item.value);
    return {
        'border-dashed border-grey-280 border-b mb-2 pb-2': index !== items.value.length - 1,
    };
});
</script>

<style scoped>
/deep/ p {
    @apply text-orange-300;
}

/deep/ body p a {
    @apply text-orange-300 underline;
}
</style>

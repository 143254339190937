<script>
import Dropdown from '@/components/DropdownV2.vue';
import DropdownItem from '@/components/DropdownItem';

export default {
    name: 'PaginationComponent',
    props: {
        list: {
            type: Object,
            required: true,
        },
        pagination: {
            type: Object,
            required: true,
        },
        ctrls: {
            type: Object,
            required: true,
        },
        scroll: {
            type: String,
            validator: function (value) {
                return ['scrollTop'].includes(value);
            },
        },
        size: {
            type: String,
            validator: function (value) {
                return ['small'].includes(value);
            },
        },
        noMargin: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Dropdown,
        DropdownItem,
    },
};
</script>
<template>
    <nav :class='{ "cb-nav-bottom-spacing": (scroll === "scrollTop") && !noMargin }'>
        <ul class='pagination-component' :class='{ "pagination-component-sm": (size === "small"), "mb-0": noMargin }'>
            <template v-if='list.reloading'>
                <b class='pagination-component-results-count'>...</b>
            </template>
            <template v-else>
                <b class='pagination-component-results-count'>{{ pagination.recordCount }}</b>
            </template>
            <span class='mr-2'> {{ $t('lbl-pagination-results') }} </span>
            <Dropdown
                transparent
                :value='pagination.limit.toString()'
                @changed='nb => ctrls.setPageLimit(nb)'
                button-extra-small
                class='border-0 border-l h-full flex items-center border-grey-100 border-solid'
            >
                <DropdownItem id='10' name='10'>
                    10
                </DropdownItem>
                <DropdownItem id='25' name='25'>
                    25
                </DropdownItem>
                <DropdownItem id='50' name='50'>
                    50
                </DropdownItem>
                <DropdownItem id='100' name='100'>
                    100
                </DropdownItem>
            </Dropdown>

            <li class='border-0 border-x h-full flex items-center px-3 border-grey-100 border-solid' :class='{ "disabled": !pagination.prev }'>
                <a href='javascript:void(0)' @click.prevent='ctrls.prevPage(scroll)'>
                    <i class='fa fa-angle-double-left'></i>
                </a>
            </li>
            <li
                v-for='page in pagination.pages'
                :key='page.number'
                :class='{ "pagination-active": page.active }'
                @click.prevent='ctrls.setPage(page.number, scroll)'
                class='border-0 border-r h-full flex items-center px-3 border-grey-100 border-solid'
            >
                <a href='javascript:void(0)'>
                    {{ page.number }}
                </a>
            </li>

            <li :class='{ "disabled": !pagination.next }' class='h-full flex items-center pl-3 pr-1'>
                <a href='javascript:void(0)' @click.prevent='ctrls.nextPage(scroll)'>
                    <i class='fa fa-angle-double-right'></i>
                </a>
            </li>
        </ul>
    </nav>
</template>

<style lang='postcss' scoped>
.pagination-component {
    @apply text-blue-400 flex flex-wrap items-center p-0 px-2 h-9 list-none border border-solid rounded-sm border-grey-200;
}

.pagination-component-results-count {
    @apply font-bold text-sm mr-2;
}
.pagination-active {
    @apply bg-blue-400;
    * {
        @apply text-white;
    }
}
</style>

<script setup>
import { ref } from 'vue';
import { Form } from 'vee-validate';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import authUtils from '@/auth/utils';
import validate from '@/validate';
import FormInput from '@/components/FormInput.vue';
import { t } from '@/i18n';
import notify from '@/notify';

const props = defineProps({
    totpChallenge: {
        type: CognitoUser,
        required: true,
    },
    onSuccess: {
        type: Function,
        required: true,
    },
});

const showError = ref(false);
const totpError = ref('');

const emits = defineEmits(['go-back']);

async function verifyTOTP (values) {
    try {
        const response = await Auth.confirmSignIn(props.totpChallenge, values.response, 'SOFTWARE_TOKEN_MFA');
        authUtils.setAuthToken(response.signInUserSession.accessToken.jwtToken);
        authUtils.setIdToken(response.signInUserSession.idToken.jwtToken);
        props.onSuccess();
    } catch (e) {
        showError.value = true;
        if (e.message === 'Invalid code received for user') {
            totpError.value = t('err-totp-token-invalid');
        } else if (e.message === 'Invalid session for the user, session can only be used once.') {
            totpError.value = t('err-unknown');
        } else if (e.message === 'Your software token has already been used once.') {
            totpError.value = t('err-totp-token-invalid');
        } else if (e.message === 'Too many invalid credentials attempts. User temporarily locked. Please try again after few seconds.') {
            totpError.value = t('err-login-attempts-exceeded');
        } else if (e.message === 'Invalid session for the user, session is expired.') {
            notify.error(t('err-login-2fa-invalid-session'));
            emits('go-back');
        } else {
            totpError.value = t('err-unknown');
        }
    }
}

function onGoBack () {
    emits('go-back');
}
</script>

<template>
    <div id='totp-form'>
        <span class='font-bold text-xl'>
            {{ $t("ttl-totp") }}
        </span>
        <p class='text-muted mt-2'>
            {{ $t("p-totp-info") }}
        </p>

        <div v-if='showError && totpError' class='rounded-md bg-red-300 text-white mb-4 px-6 py-3'>
            {{ totpError }}
        </div>

        <Form ref='totpForm' tag='div' @submit='verifyTOTP'>
            <FormInput
                name='response'
                :placeholder='$t("placeholder-totp-input")'
                rules='TOTPFormat|max:6|min:6|integer|required'
                edit
                id='response'
                :formatter='str => validate.formatString(str, "DDDDDD")'
                format-on-type
            />

            <div class='mt-6'>
                <FriendlyButton
                    label='btn-previous'
                    no-margin
                    fullwidth
                    square
                    small
                    secondary
                    @click.stop='onGoBack'
                    class='mr-3'
                />
                <FriendlyButton
                    label='btn-totp-submit'
                    no-margin
                    fullwidth
                    square
                    small
                    type='submit'
                />
            </div>

            <a :href='$t("a-faq-2fa-lost-instructions")' target='_blank' class='inline-block mt-3'>
                {{ $t('txt-faq-2fa-lost-instructions') }}
            </a>
        </Form>
    </div>
</template>

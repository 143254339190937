<script>

    export default {
        props: {
            fullwidth: Boolean,
            centered: Boolean,
            leftAlign: Boolean,
            animated: Boolean,
            animatedLong: Boolean,
            mobileOnly: Boolean,
            desktopOnly: Boolean,
        },
        computed: {
            displayed () {
                if (this.mobileOnly) {
                    return this.$store.state.gui !== 'widescreen';
                } else if (this.desktopOnly) {
                    return this.$store.state.gui === 'widescreen';
                } else {
                    return true;
                }
            },
            klass () {
                return {
                    fullwidth: this.fullwidth,
                    centered: this.centered,
                    'left-align': this.leftAlign,
                    animated: this.animated,
                    'animated-long': this.animatedLong,
                };
            },
        },
    };

</script>

<template>
    <div v-if='displayed' class='wizard-box' :class='klass'>
        <slot></slot>
    </div>
</template>

<style lang='scss' scoped>

.wizard-box {
    flex: 1;
    text-align: center;
    padding: 40px;
}
.wizard-box.fullwidth {
    max-width: 800px;
    margin: 0 auto;
}
.wizard-box.centered {
    p, h1 {
        text-align: center;
    }
}
.wizard-box.left-align {
    p, h1 {
        text-align: left;
    }
}
.wizard-box h1 {
    color: #445971;
    font-size: 44px;
    font-weight: 800;
    margin-bottom: 40px;

    &.align-left {
        text-align: left;
    }
}
.wizard-box p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
    max-width: none;
}
.wizard-box .info-box {
    padding: 10px 30px;
    background: #f8f8f8;
    margin-top: 20px;
}
.wizard-box .info-box h2 {
    text-align: left;
}

.wizard-box .info-box p {
    font-size: 14px;
    line-height: 24px;
}
.wizard-box b {
    font-weight: 600;
}
.wizard-box li {
    text-align: left;
}
.animated {
    opacity: 1;
    position: relative;
    top: 0px;
    animation-name: appear;
    animation-duration: 800ms;
    animation-iteration-count: 1;
}

@keyframes appear {
  from {
    top: 20px;
    opacity: 0;
  }

  to {
    top: 0px;
    opacity: 1;
  }
}

.animated-long {
    opacity: 1;
    position: relative;
    top: 0px;
    animation-name: appearlong;
    animation-duration: 1000ms;
    animation-iteration-count: 1;
}

@keyframes appearlong {
  from {
    top: 20px;
    opacity: 0;
  }

  10% {
    top: 20px;
    opacity: 0;
  }

  to {
    top: 0px;
    opacity: 1;
  }
}

</style>

<script>
import { gql } from '@apollo/client/core';
import Popup from '@/clientcomponents/Popup.vue';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import notify from '@/notify.js';

export default {
    props: {
        mandateId: String,
        contactEmail: String,
        small: Boolean,
        extraSmall: Boolean,
        disabled: Boolean,
        micro: Boolean,
    },
    components: {
        Popup,
        FriendlyButton,
    },
    data () {
        return {
            popupShown: false,
            success: false,
        };
    },
    emits: ['success', 'done'],
    methods: {
        async resendVoilaInvitation () {
            const { data } = await this.$apollo.mutate({
                mutation: gql`mutation ResendVoilaInvitation($id: String!) {
                    resendVoilaInvitation(voilaMandateId: $id) {
                        error {
                            code,
                            message
                        }
                    }
                }`,
                variables: {
                    id: this.mandateId,
                },
            });

            const res = data.resendVoilaInvitation;
            if (res.error) {
                notify.error(this.$t(res.error.message));
            } else {
                notify.success(this.$t('suc-voila-invitation-resent'));
                this.$emit('success');
                this.success = true;
            }
            this.closePopup();
            this.$emit('done');
        },
        openPopup () {
            this.popupShown = true;
        },
        closePopup () {
            this.popupShown = false;
        },
    },
};
</script>

<template>
    <div>
        <FriendlyButton
            class='voila-reinvite-button'
            label='btn-resend-invitation'
            :action='openPopup'
            :extra-small='extraSmall'
            :micro='micro'
            :small='small'
            :disabled='disabled'
            square
            no-margin
        />
        <Popup :show='popupShown' :close='closePopup' small class='voila-resend-invitation-modal'>
            <template #header>
                {{ $t('ttl-voila-resend-invitation') }}
            </template>

            <p>
                {{ $t('p-voila-resend-invitation', {email: contactEmail}) }}
            </p>

            <template #buttons>
                <FriendlyButton
                    label='btn-cancel'
                    class='cancel-voila-resend-invitation'
                    :action='closePopup'
                    symbol='times'
                    small
                    secondary
                />
                <FriendlyButton
                    label='btn-confirm-voila-resend-invitation'
                    :action='resendVoilaInvitation'
                    small
                    symbol='check'
                    class='confirm-voila-resend-invitation'
                />
            </template>
        </Popup>
    </div>
</template>

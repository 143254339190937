<script setup>
import { useField } from 'vee-validate';
import Toggle from '@vueform/toggle';
import Tooltip from '@/components/Tooltip';
import { watch } from 'vue';

const props = defineProps({
    value: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: false,
    },
    placeholder: {
        type: String,
        default: '',
    },
    narrow: {
        type: Boolean,
        default: false,
    },
    rules: {
        type: String,
    },
    info: String,
    edit: {
        type: Boolean,
        default: false,
    },
    disabled: Boolean,
    disabledInfo: {
        type: String,
        default: () => '',
    },
});

const emit = defineEmits(['change']);

const { checked, handleChange, errorMessage } = useField(
    props.name,
    null,
    {
        type: 'checkbox',
        label: props.label,
        initialValue: props.value,
        uncheckedValue: false,
        checkedValue: true,
    });

watch(() => props.value, (newValue) => {
    if (checked.value !== newValue) {
        handleChange(newValue);
    }
});

function onToggleChange (value) {
    emit('change', value);
    handleChange(value);
}
</script>

<template>
    <div class='form-toggle-wrapper' :class='{ narrow }'>
        <div class='form-toggle' :class='{ "has-error": !!errorMessage }'>
            <Tooltip v-if='disabled && disabledInfo.length > 0' left>
                <template #trigger>
                    <Toggle :value='checked' :disabled='true' />
                    <label :for='name' v-if='label'>
                        {{ label }}
                    </label>
                </template>
                <template #content>
                    {{ disabledInfo }}
                </template>
            </Tooltip>

            <template v-else>
                <Toggle :value='checked' @change='onToggleChange' :disabled='disabled || !edit' />
                <label :for='name' v-if='label'>
                    {{ label }}
                </label>
                <slot></slot>
                <span v-if='info' class='ml-2 font-normal'>
                    <Tooltip>
                        <template #trigger>
                            <i class='fa fa-info-circle'></i>
                        </template>
                        <template #content>
                            <div>{{ info }}</div>
                        </template>
                    </Tooltip>
                </span>
            </template>
        </div>
        <p class='form-toggle-errors' v-show='errorMessage'>
            {{ errorMessage }}
        </p>
    </div>
</template>

<style src="@vueform/toggle/themes/default.css"></style>

<style>
:root {
    --toggle-height: 1.5rem;
    --toggle-border: 0.25rem;
    --toggle-bg-on: #0d52ff;
    --toggle-border-on: #0d52ff;
    --toggle-bg-on-disabled: #89A9FB;
    --toggle-border-on-disabled: #89A9FB;
}

.toggle-container:focus {
    box-shadow: 0 0 #0000;
}
</style>

<style scoped lang='postcss'>
.form-toggle-wrapper {
    display: inline-block;
}

.form-toggle-wrapper {
    &.narrow {
        width: 320px;
    }
}

.form-toggle {
    display: flex;
    align-items: center;
}

label {
    @apply text-grey-700 font-bold m-0 ml-3 font-bold;
    transition: color linear 100ms;
}
.form-toggle-errors {
    @apply text-red-300 ml-2 inline-block font-bold text-sm transition-all mt-1;
}
.form-toggle {
    width: 100%;
}
.has-error label {
    @apply text-red-300;
}
.has-error .form-toggle {
    @apply border-red-300;
}
.has-error .form-toggle input {
    @apply text-red-300;
}
</style>

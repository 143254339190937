<script>
    import Api from '../api';
    import Loader from '../loader.js';
    import WizardTransitions from '../clientcomponents/WizardTransitions.vue';
    import WizardScreen from '../clientcomponents/WizardScreen.vue';
    import WizardBox from '../clientcomponents/WizardBox.vue';
    import NavBar from '@/components/NavBar.vue';

    export default {
        name: 'RestartEmailVerification',
        components: {
            WizardTransitions,
            WizardScreen,
            WizardBox,
            NavBar,
        },
        data () {
            return {
                loaded: false,
                resultMessage: '',
            };
        },
        created: function () {
            this.restartEmailVerification();
        },
        methods: {
            restartEmailVerification () {
                Loader.start();
                Api.restartEmailVerification(this.$route.params.token)
                    .then((data) => {
                        this.resultMessage = 'lbl-email-restart-success';
                    })
                    .catch((error) => {
                        if (error.status === 403) {
                            if (error.body.code === 'ALREADY_STARTED') {
                                this.resultMessage = 'lbl-email-restart-already-started';
                            } else if (error.body.code === 'ALREADY_ANSWERED') {
                                this.resultMessage = 'lbl-email-restart-already-answered';
                            } else {
                                this.resultMessage = 'lbl-email-restart-error';
                            }
                        } else {
                            this.resultMessage = 'lbl-email-restart-error';
                        }
                    })
                    .finally(() => {
                        this.loaded = true;
                        Loader.stop();
                    });
            },
        },
    };
</script>

<template>
    <div>
        <NavBar no-nav no-login />
        <WizardTransitions v-show='loaded'>
            <WizardScreen>
                <WizardBox animated-long centered>
                    <p>{{ $t(resultMessage) }}</p>
                </WizardBox>
            </WizardScreen>
        </WizardTransitions>
    </div>
</template>

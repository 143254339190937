<template>
    <Popup :show='show' :close='close' small>
        <template #header>
            {{ $t('ttl-terms-and-conditions') }}
        </template>
        <Translated>
            <template #en>
                <p>
                    By creating an account in MyCodabox or accepting the invitation to access it, you confirm that you are authorized on behalf of your organization to manage its account in MyCodabox in its name and on its behalf.
                </p>
                <p>
                    You are fully responsible for managing your organization's account in MyCodabox.<br>
                    The term 'management' in this respect should be interpreted as broadly as possible and includes (but is not limited to) granting/withdrawing access and/or usage rights in respect of MyCodabox to other persons within your organization, adding and managing your organization's customers and ordering/cancelling Codabox products through MyCodabox.<br>
                    Codabox bears no responsibility or liability to you or your organization in connection with the management of your organization's account.<br>
                    On behalf of your organization, you accept that your organization will indemnify Codabox against any claim in this regard from a third party.
                </p>
                <p>
                    In order to properly manage your organization's account in MyCodabox, Codabox advises you to regularly verify that the access and/or usage rights granted in respect of this account are still up-to-date.<br>
                    Via "Administration - Users" in MyCodabox, you can remove a user's access at any time.
                </p>
            </template>
            <template #nl>
                <p>
                    Door het aanmaken van een account in MyCodabox of door het aanvaarden van de uitnodiging om toegang te krijgen, bevestig je dat je namens je organisatie gemachtigd bent om in haar naam en voor haar rekening haar account in MyCodabox te beheren.
                </p>
                <p>
                    Je bent volledig verantwoordelijk voor het beheer van de account van je organisatie in MyCodabox.<br>
                    De term ‘beheer’ dient in dit opzicht zo ruim mogelijk te worden geïnterpreteerd en omvat ondermeer (maar niet uitsluitend) het verlenen/intrekken van toegangs- en/of gebruiksrechten met betrekking tot MyCodabox aan andere personen binnen je organisatie, het toevoegen en beheren van klanten van je organisatie en het bestellen/annuleren van Codabox producten via MyCodabox.<br>
                    Codabox draagt geen enkele verantwoordelijkheid of aansprakelijkheid ten aanzien van jou of je organisatie in verband met het beheer van de account van je organisatie.<br>
                    Namens je organisatie aanvaard je dat je organisatie Codabox zal vrijwaren tegen elke vordering terzake van een derde.
                </p>
                <p>
                    Met het oog op een correct beheer van de account van je organisatie in MyCodabox, raadt Codabox je aan om op regelmatige basis te verifiëren of de toegangs- en/of gebruiksrechten die zijn toegekend met betrekking tot deze account nog up-to-date zijn.<br>
                    Via “Administratie – Gebruikers” in MyCodabox kan je op elk moment de toegang van een gebruiker verwijderen.
                </p>
            </template>
            <template #fr>
                <p>
                    En créant un compte dans MyCodabox ou en acceptant l'invitation d'accès, vous confirmez que vous êtes autorisés, au nom de votre organisation, à gérer son compte dans MyCodabox en son nom et pour son compte.
                </p>
                <p>
                    Vous êtes entièrement responsable de la gestion du compte de votre organisation dans MyCodabox.<br>
                    Le terme "gestion" à cet égard doit être interprété aussi largement que possible et comprend (mais n'est pas limité à) l'octroi/le retrait des droits d'accès et/ou d'utilisation de MyCodabox à d'autres personnes au sein de votre organisation, l'ajout et la gestion des clients de votre organisation et la commande/l'annulation de produits Codabox par l'intermédiaire de MyCodabox.<br>
                    Codabox n'assume aucune responsabilité envers vous ou votre organisation en ce qui concerne la gestion du compte de votre organization.<br>
                    Au nom de votre organisation, vous acceptez que votre organisation indemnise Codabox contre toute réclamation à cet égard de la part d'un tiers.
                </p>
                <p>
                    Afin de gérer correctement le compte de votre organisation dans MyCodabox, Codabox vous conseille de vérifier régulièrement que les droits d'accès et/ou d'utilisation accordés pour ce compte sont toujours d'actualité.<br>
                    Via "Administration - Utilisateurs" dans MyCodabox, vous pouvez à tout moment supprimer l'accès d'un utilisateur.
                </p>
            </template>
        </Translated>
    </Popup>
</template>
<script>
import Popup from '@/clientcomponents/Popup.vue';
import Translated from '@/components/Translated';

export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        close: {
            type: Function,
            required: true,
        },
    },
    components: {
        Popup,
        Translated,
    },
};
</script>

<style>
p {
    @apply text-grey-600 leading-7 pb-3;
}
</style>

<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref, provide, computed, watch, onMounted, defineProps } from 'vue';
import WizardHeader from '@/components/WizardHeader';
import WizardSidePanel from '@/components/WizardSidePanel';

const props = defineProps({
    fullWidth: {
        type: Boolean,
        default: false,
    },
});

const steps = ref([]);
const currentStepIndex = ref(0);
const sidePanel = ref();

provide('currentStepIndex', currentStepIndex);
provide('sidePanel', sidePanel);
provide('steps', steps);
provide('registerStep', step => {
    steps.value.push(step);
});

function nextStep () {
    currentStepIndex.value = currentStepIndex.value + 1;
}
provide('nextStep', nextStep);

function previousStep () {
    currentStepIndex.value = currentStepIndex.value - 1;
}
provide('previousStep', previousStep);

const height = ref(0);

watch(() => currentStepIndex.value, () => {
    updateHeight();
    watchCurrentStepHeight();
});

function watchCurrentStepHeight () {
    const currentStep = steps.value[currentStepIndex.value];
    if (currentStep && currentStep.element) {
        const stepElement = currentStep.element;
        const resizeObserver = new ResizeObserver(() => {
            updateHeight();
        });
        resizeObserver.observe(stepElement);
    }
}

onMounted(() => {
    updateHeight();
    watchCurrentStepHeight();
});

function updateHeight () {
    let currentStepHeight = 0;
    const currentStep = steps.value[currentStepIndex.value];
    if (currentStep && currentStep.element) {
        currentStepHeight = currentStep.element.offsetHeight;
    }
    height.value = `height: ${currentStepHeight}px;`;
}

const stepStyle = computed(() => {
    let marginTop = 0;
    steps.value.forEach(step => {
        if (step.index < currentStepIndex.value) {
            marginTop = marginTop + step.element.offsetHeight;
        }
    });
    return `margin-top: -${marginTop}px;`;
});
</script>

<template>
    <div class='w-full mx-auto'>
        <WizardHeader />
        <div class='flex justify-center items-start'>
            <div ref='sidePanel' class='shrink-0'>
                <WizardSidePanel />
            </div>
            <div
                :style='height'
                class='transition-all duration-500 ease-in-out-quart relative w-full'
                :class='{"max-w-lg": !props.fullWidth}'
            >
                <div :style='stepStyle' class='transition-all duration-500 ease-in-out-quart'>
                    <slot
                        :next-step='nextStep'
                        :previous-step='previousStep'
                        :steps='steps'
                    ></slot>
                </div>
            </div>
        </div>
    </div>
</template>

import humane from 'humane-js';
import 'humane-js/themes/flatty.css';

/*
 * Display popup notifications.
 *
 * Ex: import notify from './notify.js'; notify.info('foobar');
 *
 * See: http://wavded.github.io/humane-js/
 *
 */

const successParams = { addnCls: 'humane-flatty-success' };
const errorParams = { addnCls: 'humane-flatty-error' };
const infoParams = { addnCls: 'humane-flatty-info' };

const notify = humane.create({
    timeout: 2500,
    waitForMove: true,
    timeoutAfterMove: 250,
    clickToClose: true,
});

notify.baseCls = 'humane-flatty';

notify.success = notify.spawn(successParams);
notify.error   = notify.spawn(errorParams);
notify.info    = notify.spawn(infoParams);

notify.successWithoutTimeout = notify.spawn({ addnCls: successParams.addnCls, timeout: 0 });
notify.errorWithoutTimeout   = notify.spawn({ addnCls: errorParams.addnCls, timeout: 0 });
notify.infoWithoutTimeout    = notify.spawn({ addnCls: infoParams.addnCls, timeout: 0 });

export default notify;

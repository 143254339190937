import { Auth } from '@aws-amplify/auth';

const TUTORIAL_COGNITO_ATTRIBUTE = 'custom:mycbx-tutorials';

const state = {
    // list of tutorials that the user has seen
    tutorials: [],
    // list of tutorials that are present in MyCodaBox
    tutorialsAvailable: [],
    // whatever the cognito states are loading or a tutorial is running
    tutorialInProgress: false,
    // loading from cognito
    tutorialsLoaded: false,
};

const mutations = {
    setTutorials (state, { tutorials }) {
        state.tutorials = tutorials;
        state.tutorialsLoaded = true;
    },
    setTutorialsAvailable (state, { tutorialsAvailable }) {
        state.tutorialsAvailable = tutorialsAvailable;
    },
    setTutorialInProgress (state) {
        state.tutorialInProgress = true;
    },
    dismissTutorial (state, { tutorialId }) {
        if (!state.tutorials.includes(tutorialId)) {
            state.tutorials.push(tutorialId);
        }
        state.tutorialInProgress = false;
    },
};

const actions = {
    async loadUserTutorials ({ state, commit }) {
        const user = await Auth.currentAuthenticatedUser();
        const attributes = user.attributes;
        if (TUTORIAL_COGNITO_ATTRIBUTE in attributes && attributes[TUTORIAL_COGNITO_ATTRIBUTE] !== '') {
            const tutorialsString = attributes[TUTORIAL_COGNITO_ATTRIBUTE];
            const tutorials = tutorialsString.split(',');
            commit('setTutorials', { tutorials });
        }
    },
    async saveUserTutorials () {
        const user = await Auth.currentAuthenticatedUser();
        const attributes = user.attributes;
        const cleanedTutorials = state.tutorials.filter(tutorialId  => state.tutorialsAvailable.includes(tutorialId));
        attributes[TUTORIAL_COGNITO_ATTRIBUTE] = cleanedTutorials.toString();
        await Auth.updateUserAttributes(user, attributes);
    },
};

const getters = {
    hasSeenTutorial: (state) => (tutorialId) => {
        return state.tutorials.includes(tutorialId);
    },
    tutorialInProgress: (state) => {
        return state.tutorialInProgress;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

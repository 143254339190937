<script setup>
import { computed, ref, watch } from 'vue';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import CodaDownloadButtons from '@/components/CodaDownloadButtons.vue';
import FormCheckbox from '@/components/FormCheckbox.vue';
import { datadogRum } from '@datadog/browser-rum';
import Popup from '@/clientcomponents/Popup.vue';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';

const props = defineProps({
    mandate: {
        type: Object,
        required: true,
    },
    clientV2: {
        type: Object,
        required: true,
    },
    nextButton: {
        type: Boolean,
        default: false,
    },
    close: {
        type: Function,
        required: true,
    },
    show: {
        type: Boolean,
        required: true,
    },
});

const continueButtonLabel = computed(() => {
    return props.nextButton ? 'btn-next' : 'btn-close';
});
const continueButtonSymbol = computed(() => props.nextButton ? 'arrow-right' : 'times');

// since watch only triggers on change and not the initial load, currentMandate would not be set on initial load
// so currentMandate is a calculated fiels that returns the local version (when available) and a fallback to the original otherwise.
const localMandate = ref();
const currentMandate = computed(() => localMandate.value ? localMandate.value : props.mandate);

const isTTMOnly = computed(() => {
    return currentMandate.value?.bankAccounts.filter(ba => ba.state !== 'archived').every(ba => ba.flowState === 'draft-active-bank-mandate');
});
const isSignHerePackageBeingCreated = computed(() => (currentMandate.value?.bank.isSignhereSupported || isTTMOnly.value) && !currentMandate.value?.signHerePackage?.packageSignUrl);
const digitalSignatureURL = computed(() => currentMandate.value?.twikeyUrl ? currentMandate.value?.twikeyUrl : currentMandate.value?.signHerePackage?.packageSignUrl);

const mandateType = computed(() => {
    if (isTTMOnly.value) {
        return 'digital';
    }

    switch (props.mandate.bank.slug) {
        case 'kbc':
        case 'cbc':
            return 'kcbc';
        case 'delta-lloyd':
            return 'paper';
    }
    return 'digital';
});

const signWindowRef = ref(null);
const pollerId = ref(null);
const iHaveSigned = ref(false);

function resetStates () {
  // when mandates change
    clearInterval(pollerId.value);
    signWindowRef.value = null;
    iHaveSigned.value = false;
    localMandate.value = props.mandate;
    if (isSignHerePackageBeingCreated.value) {
        pollForSigningUrl();
    }
}

function refreshMandate () {
    const { result, onResult } = useQuery(
      gql`query RefreshCodaMandate($clientId: String, $codaMandateId: String) {
            codaMandate(clientId:$clientId, codaMandateId:$codaMandateId) {
                id
                clientId
                fiduciaryId
                bankId
                bank {
                    id
                    name
                    slug
                    abbreviation
                    isSupported
                    isTwikeySupported
                    disableSendPdfMandate
                    isCaroSupported
                    isStopCodaSupported
                    regexSavings
                    isSignhereSupported
                }
                state
                reasonCode
                reasonNote
                stateDate
                bankAccounts{
                    id
                    type
                    bankId
                    state
                    stateDate
                    flowState
                    bankMandateState
                    bankMandateStateDate
                    iban
                }
                signHerePackage {
                    packageStatus
                    packageSignUrl
                    packageRejectionReason
                }
                twikeyUrl
                twikeyUrlCreatedAt
                hasTeletransmissionPdf
                reminders {
                    date
                    targetEmail
                    type
                }
            }
        }`,
        {
            clientId: props.clientV2.id,
            codaMandateId: currentMandate.value.id,
        },
  );
    onResult(() => {
        const res = result.value.codaMandate;
        if (currentMandate.value.id === res.id) {
            localMandate.value = res;
        }
    });
}

function pollForSigningUrl () {
    refreshMandate();
    pollerId.value = setInterval(() => {
        if (!isSignHerePackageBeingCreated.value) {
            clearInterval(pollerId.value);
        } else {
            refreshMandate();
        }
    }, 1000);
}

function startSigningWindow (url) {
    if (signWindowRef.value && !signWindowRef.value.closed) {
    // still there, request focus to bring it to the front
        signWindowRef.value.focus();
        return;
    }
    signWindowRef.value = window.open(url, 'mandate' + props.mandate.id, 'toolbar=no,scrollbars=yes,resizable=yes,menubar=no,location=no');
}

watch(() => props.mandate.id, resetStates);

function closePopup (force) {
    clearInterval(pollerId.value);
    signWindowRef.value = null;
    iHaveSigned.value = false;
    props.close(force);
    datadogRum.addAction('coda-signature-popup-closed', {
        clientId: props.clientV2.id,
        isDirectCustomer: props.isDirectCustomer,
        iHaveSigned: iHaveSigned.value,
    });
}

// since we are responsible for updating the mandate, we should do this when we start.
// Otherwise, we don't refresh for the first mandate we serve
if (isSignHerePackageBeingCreated.value) {
    pollForSigningUrl();
}

</script>

<template>
    <Popup :show='show' :close='() =>closePopup(true)' small>
        <template #header>
            {{ $t('sign-coda.title') }} {{ mandate.bank.name }}
        </template>
        <template v-if='mandateType === "digital"'>
            <p data-signing-flow='digital'>
                {{ $t('sign-coda.digital.intro', {bank_name: mandate.bank.name}) }}
            </p>

            <div class='button-centered'>
                <FriendlyButton
                    v-if='digitalSignatureURL'
                    square
                    symbol='pencil-square-o'
                    extra-small
                    no-margin
                    :action='() => startSigningWindow(digitalSignatureURL)'
                >
                    {{ $t('sign-coda.digital.button') }}
                </FriendlyButton>
                <p v-else>
                    {{ $t('lbl-wait-digital-signature-url') }}... <i class='fa fa-circle-o-notch fa-spin'></i>
                </p>
            </div>
        </template>
        <template v-if='mandateType === "kcbc"'>
            <p data-signing-flow='kcbc' class='pb-6 text-lg font-bold'>
                {{ $t('sign-coda.kcbc.intro', {bank_name: mandate.bank.name}) }}
            </p>

            <div class='flex pb-6'>
                <span class='font-bold pr-4 whitespace-nowrap'>{{ $t('sign-coda.step-1') }} </span>
                <div>
                    <span class='pr-2 pb-4'>
                        {{ $t('sign-coda.kcbc.step-1') }}
                    </span> <br>
                    <a v-if='digitalSignatureURL' :href='digitalSignatureURL' target='_blank'>
                        <FriendlyButton
                            class='pt-4'
                            v-if='digitalSignatureURL'
                            symbol='pencil-square-o'
                            square
                            extra-small
                            no-margin
                            :action='() => emits("hasClickedSignAction")'
                        >
                            {{ $t('sign-coda.kcbc.step-1-button') }}
                        </FriendlyButton>
                    </a>
                    <span v-else>
                        {{ $t('lbl-wait-digital-signature-url') }}... <i class='fa fa-circle-o-notch fa-spin'></i>
                    </span>
                </div>
            </div>

            <div class='flex'>
                <span class='font-bold pr-4 whitespace-nowrap'>{{ $t('sign-coda.step-2') }}</span>
                <div>
                    <div>
                        <span>
                            {{ $t('sign-coda.kcbc.step-2-1') }}
                        </span>
                        <div class='ml-2'>
                            <ul>
                                <li v-for='bankAccount in mandate.bankAccounts'>
                                    {{ bankAccount.iban }}
                                </li>
                            </ul>
                        </div>
                        <span class='pr-2'>
                            {{ $t('sign-coda.kcbc.step-2-2') }}
                        </span> <br>
                    </div>
                    <a :href='$t("sign-coda.kcbc-activation-link")' target='_blank'>
                        <FriendlyButton
                            class='pt-4'
                            square
                            extra-small
                            no-margin
                            symbol='external-link'
                        >
                            {{ $t('sign-coda.kcbc.step-2-button') }}
                        </FriendlyButton>
                    </a>
                </div>
            </div>
        </template>
        <template v-if='mandateType === "paper"'>
            <p data-signing-flow='paper' class='pb-6 text-lg font-bold'>
                {{ $t('sign-coda.paper.intro', {bank_name: mandate.bank.name}) }}
            </p>
            <div class='flex pb-6'>
                <span class='font-bold pr-4 whitespace-nowrap'>{{ $t('sign-coda.step-1') }}</span>
                <div>
                    <p>{{ $t('sign-coda.paper.step-1') }}</p>
                    <div>
                        <CodaDownloadButtons :mandate='mandate' show-in-popup class='pt-4' />
                    </div>
                </div>
            </div>
            <div class='flex pb-6'>
                <span class='font-bold pr-4 whitespace-nowrap'>{{ $t('sign-coda.step-2') }}</span>
                <p class='mb-6'>
                    {{ $t('sign-coda.paper.step-2') }}
                </p>
            </div>
            <div class='flex'>
                <span class='font-bold pr-4 whitespace-nowrap'>{{ $t('sign-coda.step-3') }}</span>
                <p>{{ $t('sign-coda.paper.step-3') }} <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>. </p>
            </div>
        </template>

        <div class='flex items-center justify-between mt-8'>
            <FormCheckbox
                name='hasSigned'
                id='has-signed-checkbox'
                :label='$t("lbl-i-have-signed-mandates")'
                :model-value='iHaveSigned'
                @update:model-value='(event) => (iHaveSigned = event)'
                edit
                narrow
            />
            <FriendlyButton
                id='continueButton'
                :label='continueButtonLabel'
                :action='() => closePopup(false)'
                :disabled='!iHaveSigned'
                :symbol='continueButtonSymbol'
                extra-small
                square
            />
        </div>
    </Popup>
</template>

<style scoped>
h1 {
  @apply flex mb-5 text-4xl mt-4
}

h2 {
  @apply flex mb-2 text-2xl mt-2
}

.button-centered {
  @apply mt-3 mb-6 text-center
}
</style>

<script>
    export default {
        name: 'WarningComponent',
        props: {
            flex: {
                type: Boolean,
                default: true,
            },
        },
    };
</script>
<template>
    <div
        class='text-white mb-3 px-4 py-2 bg-orange-300 inline-block rounded-md items-center'
        :class='{"flex mt-5": flex}'
    >
        <i class='fa fa-exclamation-triangle mr-3'></i>
        <slot name='header'></slot>
        <slot></slot>
    </div>
</template>
